// userdata
// userdata
export const USER_TYPE = 'USER_TYPE'
export const USER_SUB_TYPE = 'USER_SUB_TYPE'
export const EDUCATION_LEVELS = 'EDUCATION_LEVELS'
export const USER_GENDER = 'USER_GENDER'
export const USER_DISTRICT = 'USER_DISTRICT'
export const SELECTED_USER_DISTRICT = 'SELECTED_USER_DISTRICT'
export const SET_NEWS = 'SET_NEWS'
export const SET_CAREER = 'SET_CAREER'
export const USER_CITIES = 'USER_CITIES'
export const SET_ALL_JOB_BY_EDU = 'SET_ALL_JOB_BY_EDU'
export const SET_JOB_NOTIFICATION = 'SET_JOB_NOTIFICATION'
export const USER_QUALIFICATION = 'USER_QUALIFICATION'
export const USER_INSTITUTION = 'USER_INSTITUTION'
export const USER_SUBDISTRICT = 'USER_SUBDISTRICT'
export const USER_SPECILIZATION = 'USER_SPECILIZATION'
export const USER_COURSENATURE = 'USER_COURSENATURE'
export const USER_LANGUAGE = 'USER_LANGUAGE'
export const ORG_TYPE = 'ORG_TYPE'
export const ORG_CATEGORY = 'ORG_CATEGORY'
export const SET_ALL_DISTRICTS = 'SET_ALL_DISTRICTS'
export const ORG_STATE = 'ORG_STATE'
export const FUNCTIONAL_AREA = 'FUNCTIONAL_AREA'
export const SECTOR = 'SECTOR'
export const SALARY_TYPE = 'SALARY_TYPE'
export const JOB_SHIFT = 'JOB_SHIFT'
export const SET_SEEKER_TYPE = 'SET_SEEKER_TYPE'
export const SET_LOCAL_SERVICES = 'SET_LOCAL_SERVICES'
export const SET_SERVICE_SUGGESTION = 'SET_SERVICE_SUGGESTION'
export const TAB_SUBMITTED = 'TAB_SUBMITTED'
export const SET_ALL_LOCAL_SERVICES = 'SET_ALL_LOCAL_SERVICES'
export const SET_SCHEDULED_SELF_EMPLOYMENT = 'SET_SCHEDULED_SELF_EMPLOYMENT'
export const SET_SELF_EMP_CAREERS = 'SET_SELF_EMP_CAREERS'
export const SET_SELF_EMP_KEYWORDS = 'SET_SELF_EMP_KEYWORDS'
export const SET_RELEVANT_SELF_EMPLOYMENT = 'SET_RELEVANT_SELF_EMPLOYMENT'
export const SET_SELF_EMP_INTEREST = 'SET_SELF_EMP_INTEREST'
export const SET_FOREIGN_JOBS_INTERVIEW = 'SET_FOREIGN_JOBS_INTERVIEW'
export const SET_APPLIED_FOREIGN_JOBS = 'SET_APPLIED_FOREIGN_JOBS'
export const SET_SEARCH_FOREIGN_JOB_RESULTS = 'SET_SEARCH_FOREIGN_JOB_RESULTS'
export const SET_FOREIGN_JOB_MODES = 'SET_FOREIGN_JOB_MODES'
export const SET_FOREIGN_JOB_TYPES = 'SET_FOREIGN_JOB_TYPES'
export const SET_PROVIDER_TYPES = 'SET_PROVIDER_TYPES'
export const SET_CHAT_INVITES = 'SET_CHAT_INVITES'
export const SET_CHATS = 'SET_CHATS'
export const SET_CHAT_MESSAGES = 'SET_CHAT_MESSAGES'
export const SET_SELF_EMPLOYMENT_DATA = 'SET_SELF_EMPLOYMENT_DATA'
export const SELF_EMP_DROPDOWN_DATA = 'SELF_EMP_DROPDOWN_DATA'
export const SET_USER_PROFILE_INTEREST = 'SET_USER_PROFILE_INTEREST'
export const SET_DISTANCE = 'SET_DISTANCE'
export const SET_CONSTITUENCY = 'SET_CONSTITUENCY'

export const SET_SELF_EMPLOYMENT_SECTOR = 'SET_SELF_EMPLOYMENT_SECTOR'
export const SET_SEEKER_FOR_FORGIEN_JOB = 'SET_SEEKER_FOR_FORGIEN_JOB'
export const SET_UPCOMING_SELF_EMPLOYMENT = 'SET_UPCOMING_SELF_EMPLOYMENT'
export const SET_APPLIED_LOCAL_SERVICES = 'SET_APPLIED_LOCAL_SERVICES'
export const SET_EVENTS = 'SET_EVENTS'
export const SET_OTP_VERIFICATION = 'SET_OTP_VERIFICATION'
export const SET_PROGRESS = 'SET_PROGRESS'
export const GET_INVITE_USERS = 'GET_INVITE_USERS'
export const SET_DBEES_ADDRESS = 'SET_DBEES_ADDRESS'
 //Login User
export const GET_LOGIN = 'GET_LOGIN'
export const CREATE_USER = 'CREATE_USER'
export const SET_USER_VERIFIED_OBJ = 'SET_USER_VERIFIED_OBJ'
// Business
export const GET_BUSINESSES = 'GET_BUSINESSES'
export const SET_BUSINESSES = 'SET_BUSINESSES'
// Category
export const GET_CATEGORIES = 'GET_CATEGORIES'
export const SET_CATEGORIES = 'SET_CATEGORIES'
// Product
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const SET_PRODUCTS = 'SET_PRODUCTS'
export const GET_SELLERS_PRODUCTS = 'GET_SELLERS_PRODUCTS'
//Notifications
export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const UNSET_NOTIFICATION = 'UNSET_NOTIFICATION'
//Loader
export const SET_LOADER = 'SET_LOADER'
export const UNSET_LOADER = 'UNSET_LOADER'

//RegisterFunctions
export const JOBSEEKER = 'JOBSEEKER'
export const EMPLOYER = 'EMPLOYER'
//Loderer
export const SET_LODER = 'SET_LODER'
export const UNSET_LODER = 'UNSET_LODER'
export const USER_VERIFIED = 'USER_VERIFIED'
export const USER_RESET = 'USER_RESET'
export const OPEN_OPT = 'OPEN_OPT'

// Jobseeker Details
export const SET_FOREIGN_COUNSELLING = 'SET_FOREIGN_COUNSELLING'
export const SET_JOB_MELA_JOBS_LOADING = 'SET_JOB_MELA_JOBS_LOADING'
export const SET_ISSUE_TYPES = 'SET_ISSUE_TYPES'
export const SET_TICKETS = 'SET_TICKETS'
export const SET_APPLIED_EVENTS = 'SET_APPLIED_EVENTS'
export const SET_JOBS_APPLIED = "SET_JOBS_APPLIED"
export const GET_JOBSEEKER_PROFILE = 'GET_JOBSEEKER_PROFILE'
export const SET_JOBSEEKER_PROFILE = 'SET_JOBSEEKER_PROFILE'
export const SET_MARITAL = 'SET_MARITAL'
export const SET_COUNTRY='SET_COUNTRY'
export const SET_RELIGION = 'SET_RELIGION'
export const SET_SHORTLISTED = 'SET_SHORTLISTED'
export const SET_CATEGORY = 'SET_CATEGORY'
export const SET_BLOOD_G = 'SET_BLOOD_G'
export const SET_DISABILITY = 'SET_DISABILITY'
export const SET_EYESIGHT = 'SET_EYESIGHT'
export const SET_CAPACITY = 'SET_CAPACITY'
export const SET_RELATION = 'SET_RELATION'
export const SET_JOBSEEKER_PHYSICAL = 'SET_JOBSEEKER_PHYSICAL'
export const SET_JOBSEEKER_ADDRESSES = 'SET_JOBSEEKER_ADDRESSES'
export const USER_JOBSEEKER_DISTRICT = 'USER_JOBSEEKER_DISTRICT'
export const USER_JOBSEEKER_CITIES = 'USER_JOBSEEKER_CITIES'
export const USER_JOBSEEKER_SUBDISTRICT = 'USER_JOBSEEKER_SUBDISTRICT'
export const USER_JOBSEEKER_EDUCATIONS = 'USER_JOBSEEKER_EDUCATIONS'
export const GET_SALARY_TYPE = 'GET_SALARY_TYPE'
export const SET_SALARY_TYPE = 'SET_SALARY_TYPE'
export const HALL_TICKET_PRINT = 'HALL_TICKET_PRINT'
export const EXP_UPDATED = 'EXP_UPDATED'
export const SET_JOB_PLACEMENTS = 'SET_JOB_PLACEMENTS'



// JobProvider Details
export const GET_JOBPROVIDER_PROFILE = 'GET_JOBSEEKER_PROFILE'
export const SET_JOBPROVIDER_PROFILE = 'SET_JOBSEEKER_PROFILE'
export const SET_SCHEDULED_INTERVIEWS = 'SET_SCHEDULED_INTERVIEWS'
export const SET_POST_ARCHIVE = 'SET_POST_ARCHIVE'
export const SET_RECENT_JOBS = 'SET_RECENT_JOBS'
export const SET_JOB_MELA_PARTICIPATION = 'SET_JOB_MELA_PARTICIPATION'
export const SET_JOB_MELA_ATTECH = 'SET_JOB_MELA_ATTECH'
export const SET_ACTIVE_JOB_MELA = 'SET_ACTIVE_JOB_MELA'
export const SET_EXPIRE_JOB = 'SET_EXPIRE_JOB'
export const APPLY_FOR_COUNSELLING = 'APPLY_FOR_COUNSELLING'
export const SET_COUNSELOR_EDUCATION = 'SET_COUNSELOR_EDUCATION'
export const SET_CONFIRMED_CANDIDATES = 'SET_CONFIRMED_CANDIDATES'
export const SET_COUNSELLING_BY_ID = 'SET_COUNSELLING_BY_ID'
export const SET_FEEDBACK_LIST = 'SET_FEEDBACK_LIST'
export const SET_SKILL_APPLIED = 'SET_SKILL_APPLIED'
export const SET_RESUME_DATA = 'SET_RESUME_DATA'
export const SET_SCHEDULED_STUDY = 'SET_SCHEDULED_STUDY'
export const SET_APPLIED_STUDY = 'SET_APPLIED_STUDY'
export const SET_STUDY_AREAS = 'SET_STUDY_AREAS'
export const SET_MONTHS = 'SET_MONTHS'
export const SET_APPLIED_EVENT_CANDIDATE = 'SET_APPLIED_EVENT_CANDIDATE'
export const SET_GENERATE_EXCEL = 'SET_GENERATE_EXCEL'
export const SET_SHORLISTED_FORGIEN_STUDY_APPLICANTS = 'SET_SHORLISTED_FORGIEN_STUDY_APPLICANTS'
export const SET_SCHEDULED_FOREIGN_JOBS = 'SET_SCHEDULED_FOREIGN_JOBS'

export const SET_TIME_SLOT = 'SET_TIME_SLOT'
export const SET_APPLIED_SESSION_BY_SEEKER = 'SET_APPLIED_SESSION_BY_SEEKER'
export const SET_SESSION_DATA = "SET_SESSION_DATA"

export const SET_APPLICANTS_ACCEPTED_INVITE = 'SET_APPLICANTS_ACCEPTED_INVITE'
export const SET_SCHDULED_JOBS_PROVIDER = 'SET_SCHDULED_JOBS_PROVIDER'
export const SET_SCHEDULED_SERVICE = 'SET_SCHEDULED_SERVICE'





export const SET_ORGANIZATION_TYPE = 'SET_ORGANIZATION_TYPE'
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS'
export const APPLY_FOR_JOB = 'APPLY_FOR_JOB'
export const SET_MATCHING = 'SET_MATCHING'

export const SET_SELF_EMPLOYMENT_BY_ID = 'SET_SELF_EMPLOYMENT_BY_ID'
export const SET_MARGIN_MONEY = 'SET_MARGIN_MONEY'
export const SET_SUBSIDY = 'SET_SUBSIDY'
export const SET_AMOUNT = 'SET_AMOUNT'
export const SET_COUNSELLING_TYPE = 'SET_COUNSELLING_TYPE'
export const SET_SELF_EMPLOYMENT_SEARCH_RESULTS = 'SET_SELF_EMPLOYMENT_SEARCH_RESULTS'
export const SET_SKILL_SEARCH = 'SET_SKILL_SEARCH'
export const SET_COUNSELLING_RESULTS = 'SET_COUNSELLING_RESULTS'
export const SET_JOB_MELA_RESULTS = 'SET_JOB_MELA_RESULTS'
export const SET_JOB_MELA = 'SET_JOB_MELA'
export const SET_JOIN_BY_DAYS = 'SET_JOIN_BY_DAYS'
export const SET_SCHEDULED_JOBS = 'SET_SCHEDULED_JOBS'
export const SET_JOB_INVITES = 'SET_JOB_INVITES'
export const SET_UPCOMING_JOBS = 'SET_UPCOMING_JOBS'
export const SET_SCHEDULED_SKILLS = 'SET_SCHEDULED_SKILLS'
export const SET_UPCOMING_SKILLS = 'SET_UPCOMING_SKILLS'
export const SET_RECOMMENDED_JOBS = 'SET_RECOMMENDED_JOBS'
export const SET_RECOMMENDED_SKILLS = 'SET_RECOMMENDED_SKILLS'
export const SET_SKILL_ARCHIEVE = 'SET_SKILL_ARCHIEVE'
export const SET_APPLIED_SKILLS_TRAINING = 'SET_APPLIED_SKILLS_TRAINING'
export const SET_TRAINING_INTEREST = 'SET_TRAINING_INTEREST'
export const SET_COURSE_APPLICANTS = 'SET_COURSE_APPLICANTS'
export const SET_RECOMMENDED_COUNSELLING = 'SET_RECOMMENDED_COUNSELLING'
export const SET_RECOMMENDED_MELAS = 'SET_RECOMMENDED_MELAS'
export const SET_APPLIED_PAGES = 'SET_APPLIED_PAGES'
export const SET_CAREER_COUNSELLING = 'SET_CAREER_COUNSELLING'
export const SET_APPLIED_SESSION = 'SET_APPLIED_SESSION'
export const SET_SESSION_APPLICANTS = 'SET_SESSION_APPLICANTS'
export const SET_SCHEDULED_JOB_MELA = 'SET_SCHEDULED_JOB_MELA'
export const USER_SKILLS = 'USER_SKILLS'
export const SET_USER_COURSENATURE = 'SET_USER_COURSENATURE'
export const SET_EXPERIENCE_RESULTS = 'SET_EXPERIENCE_RESULTS'
export const SET_JOBPROVIDER_ADDRESSES = 'SET_JOBPROVIDER_ADDRESSES'
export const SET_PROVIDER_SEARCH_RESULTS = 'SET_PROVIDER_SEARCH_RESULTS'
export const SET_EDUCATION_LEVEL = 'SET_EDUCATION_LEVEL'
export const SET_REDIRECTION_LINK = 'SET_REDIRECTION_LINK'
export const USER_JOBSEEKER_EXPERIENCE = 'USER_JOBSEEKER_EXPERIENCE'
export const SET_APPLIED_INTERVIEWS = 'SET_APPLIED_INTERVIEWS'
export const SET_SELECTED_APPLICANTS = 'SET_SELECTED_APPLICANTS'
export const SET_JOB_STATUS = 'SET_JOB_STATUS'
export const REQUEST_COUNSELLING='REQUEST_COUNSELLING'
export const INDIVIDUAL_REQUEST='INDIVIDUAL_REQUEST'
export const INDIVIDUALS_REQUEST='INDIVIDUALS_REQUEST'
export const SET_APPLIED_JOBS = 'SET_APPLIED_JOBS'
export const SET_HOME_LOGIN = 'SET_HOME_LOGIN'
export const SET_ADDITIONAL_SKILLS = 'SET_ADDITIONAL_SKILLS'
export const SET_SEEKER_EDUCATION = 'SET_SEEKER_EDUCATION'
export const SET_SEEKER_PERCENTAGE = 'SET_SEEKER_PERCENTAGE'
export const SET_QUALIFICATION = 'SET_QUALIFICATION'
export const SET_ALL_MELAS = 'SET_ALL_MELAS'
export const SET_GOVT_JOBS = 'SET_GOVT_JOBS'
export const SET_JOBS_COUNT = 'SET_JOBS_COUNT'
export const SET_PRIVATE_JOBS = 'SET_PRIVATE_JOBS'
export const SET_VISITOR_COUNT = 'SET_VISITOR_COUNT'
export const SET_MESSAGE = 'SET_MESSAGE'
export const JOB_POSTED = 'JOB_POSTED'
export const SET_EXP = 'SET_EXP'
export const SET_STATUS = 'SET_STATUS'
export const SET_HALL_TICKET = 'SET_HALL_TICKET'


//Skill provider
export const SET_SKILL_PROVIDER_PROFILE = 'SET_SKILL_PROVIDER_PROFILE'
export const SET_SKILL_PROVIDER_SCHEDULED_SKILLS = 'SET_SCHEDULED_SKILLS'

// Counselling provider
export const SET_PROVIDER_COUNSELLING_TYPES = 'SET_PROVIDER_COUNSELLING_TYPES'
export const SET_PROVIDER_COUNSELLING_MODES = 'SET_PROVIDER_COUNSELLING_MODES'
export const SET_COUNSELLING_PROVIDER_PROFILE = 'SET_COUNSELLING_PROVIDER_PROFILE'
export const SET_EMPLOYER = 'SET_EMPLOYER'
export const SET_POST_COUNSELLING_STATUS = 'SET_POST_COUNSELLING_STATUS'
export const SET_SCHEDULED_SESSION = 'SET_SCHEDULED_SESSION'
export const SET_UPCOMING_SESSION = 'SET_UPCOMING_SESSION'
export const SET_ARCHIEVE_SESSION = 'SET_ARCHIEVE_SESSION'
export const SESSION_UPDATED = 'SESSION_UPDATED'
export const RESET_VERIFY = 'RESET_VERIFY'

// local service provider 
export const LSP_SERVICES = 'LSP_SERVICES'
export const SERVICE_POSTED = 'SERVICE_POSTED'
export const SET_RECENT_SERVICES = 'SET_RECENT_SERVICES'
export const SET_SERVICE_ARCHIVE = 'SET_SERVICE_ARCHIVE'
export const SET_APPLIED_CANDIDATES = 'SET_APPLIED_CANDIDATES'
export const SET_CATEGORY_SERVICE = 'SET_CATEGORY_SERVICE'
export const SET_SCHEDULE_VISITS = 'SET_SCHEDULE_VISITS'
export const SET_SERVICE_FEEDBACK = 'SET_SERVICE_FEEDBACK'
export const SET_SERVICE_ARCHIEVE = 'SET_SERVICE_ARCHIEVE'
export const SET_SERVICE_STATUS = 'SET_SERVICE_STATUS'
export const SET_SERVICE_APPLIED = 'SET_SERVICE_APPLIED'



// Forgien job provider
export const SET_FORGIEN_PROVIDER_PROFILE = 'SET_FORGIEN_PROVIDER_PROFILE'
export const SET_FORGIEN_POST_STATUS = 'SET_FORGIEN_POST_STATUS'
export const SET_FORGIEN_RECENT_JOBS = 'SET_FORGIEN_RECENT_JOBS'
export const SET_FORGIEN_SCHEDULED_JOBS = 'SET_FORGIEN_SCHEDULED_JOBS'
export const SET_FORGIEN_POST_ARCHIVE = 'SET_FORGIEN_POST_ARCHIVE' 
export const SET_APPLIED_FORGIEN_JOB = 'SET_APPLIED_FORGIEN_JOB'
export const SET_SELECTED_FORGIEN_APPLICANTS ='SET_SELECTED_FORGIEN_APPLICANTS'
export const FORGIEN_JOB_UPDATE = 'FORGIEN_JOB_UPDATE'
export const SET_FOREIGN_UNEXPIRED_JOBS = 'SET_FOREIGN_UNEXPIRED_JOBS'
export const SET_JOB_TITLES = 'SET_JOB_TITLES'

// Fogien study Provider
export const SET_FORGIEN_STUDY_PROVIDER_PROFILE = 'SET_FORGIEN_STUDY_PROVIDER_PROFILE'
export const SET_APPLIED_FORGIEN_STUDY = 'SET_APPLIED_FORGIEN_STUDY'
export const SET_SELECTED_FORGIEN_STUDY_APPLICANTS = 'SET_SELECTED_FORGIEN_STUDY_APPLICANTS' 
export const SET_FORGIEN_SCHEDULED_STUDY = 'SET_FORGIEN_SCHEDULED_STUDY'
export const SET_FORGIEN_STUDY_ARCHIVE = 'SET_FORGIEN_STUDY_ARCHIVE'
export const SET_FORGIEN_RECENT_STUDY = 'SET_FORGIEN_RECENT_STUDY'
export const SET_SEEKER_FOR_FORGIEN_STUDY = 'SET_SEEKER_FOR_FORGIEN_STUDY'
export const SET_STATUS_FOR_FORGIEN_STUDY = 'SET_STATUS_FOR_FORGIEN_STUDY'
export const SET_AREA_OF_INTEREST = 'SET_AREA_OF_INTEREST'
export const SET_COUNSELLOR_SEARCH_RESULT = 'SET_COUNSELLOR_SEARCH_RESULT'
export const SET_SKILLDEVELOPMENT_RESULTS = 'SET_SKILLDEVELOPMENT_RESULTS'
export const SET_SKILLDEVELOPMENTALL_RESULTS = 'SET_SKILLDEVELOPMENTALL_RESULTS'
export const APPLY_SKILL_COURSE = 'APPLY_SKILL_COURSE'
export const GET_APPLIEDSKILL_COURSES = 'GET_APPLIEDSKILL_COURSES'
export const GET_ONGOINGSKILL_COURSES = 'GET_ONGOINGSKILL_COURSES'
export const GET_SHEMES_DISPLAY = 'GET_SHEMES_DISPLAY'
export const GET_SECTOR_LIST ='GET_SECTOR_LIST'
export const  GET_DISTRICT_LIST = 'GET_DISTRICT_LIST'
export const  GET_ALLSKILL_LIST = 'GET_ALLSKILL_LIST'
export const  GET_SKILLCOURSE_LIST = 'GET_SKILLCOURSE_LIST'
export const  GET_VEDIO = 'GET_VEDIO'
export const SET_SCHEME = 'SET_SCHEME'



//PSDM Skill Program

export const SET_PSDM_SKILL_PROGRAM = 'SET_PSDM_SKILL_PROGRAM'
export const SET_MOBILE_APP_DATA = 'SET_MOBILE_APP_DATA'
export const SKILL_EDUCATION_LEVELS = 'SKILL_EDUCATION_LEVELS'

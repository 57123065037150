import axios from 'axios';
import qs from 'qs';
import ServerSetting from '../config.json';
import { push } from 'react-router-redux';
// import {set_Loader, unset_Loader, setNotification, Logout} from './accountAction';
import { set_Loader, unset_Loader, setNotification } from './accountAction';
import {
    SET_FOREIGN_COUNSELLING,
    SET_SCHEDULED_SERVICE,
    SET_RELEVANT_SELF_EMPLOYMENT,
    SET_SELF_EMP_CAREERS,
    SET_SELF_EMP_INTEREST,
    SET_TRAINING_INTEREST,
    SET_SEARCH_FOREIGN_JOB_RESULTS,
    SET_TICKETS,
    SET_ISSUE_TYPES,
    SET_COUNSELLOR_SEARCH_RESULT,
    SET_PSDM_SKILL_PROGRAM,
    SET_USER_PROFILE_INTEREST,
    SET_APPLIED_EVENTS,
    SET_ALL_JOB_BY_EDU,
    SET_SELF_EMPLOYMENT_BY_ID,
    SET_ORGANIZATION_TYPE,
    SET_SEARCH_RESULTS,
    USER_JOBSEEKER_EDUCATIONS,
    SET_JOIN_BY_DAYS,
    HALL_TICKET_PRINT,
    SET_CAREER_COUNSELLING,
    SET_MARGIN_MONEY,
    SET_SUBSIDY,
    SET_AMOUNT,
    SET_COUNSELLING_TYPE,
    SET_SELF_EMPLOYMENT_SEARCH_RESULTS,
    SET_SCHEDULED_JOB_MELA,
    SET_DISABILITY,
    SET_JOBSEEKER_ADDRESSES,
    SET_CAPACITY,
    SET_JOBSEEKER_PROFILE,
    SET_SKILL_SEARCH,
    SET_RECOMMENDED_MELAS,
    SET_SCHEDULED_JOBS,
    SET_JOB_INVITES,
    SET_UPCOMING_JOBS,
    SET_SCHEDULED_SKILLS,
    SET_UPCOMING_SKILLS,
    SET_APPLIED_PAGES,
    SET_MARITAL,
    SET_RELIGION,
    SET_CATEGORY,
    SET_RELATION,
    SET_EYESIGHT,
    SET_COUNSELLING_RESULTS,
    SET_RECOMMENDED_COUNSELLING,
    SET_JOBSEEKER_PHYSICAL,
    SET_BLOOD_G,
    SET_JOB_MELA_RESULTS,
    SET_RECOMMENDED_JOBS,
    SET_RECOMMENDED_SKILLS,
    SET_EXPERIENCE_RESULTS,
    USER_JOBSEEKER_EXPERIENCE,
    SET_JOB_MELA,
    SET_JOB_MELA_ATTECH,
    SET_ADDITIONAL_SKILLS,
    SET_SEEKER_EDUCATION,
    SET_SEEKER_PERCENTAGE,
    SET_QUALIFICATION,
    SET_MESSAGE,
    SET_STATUS,
    SET_HALL_TICKET,
    APPLY_FOR_COUNSELLING,
    SET_SKILL_APPLIED,
    APPLY_FOR_JOB,
    EXP_UPDATED,
    SET_JOBS_APPLIED,
    SET_COUNSELLING_BY_ID,
    SET_CAREER,
    SET_SCHEDULED_FOREIGN_JOBS,
    SET_SCHEDULED_STUDY,
    SET_SCHEDULED_SELF_EMPLOYMENT,
    SET_FOREIGN_JOBS_INTERVIEW,
    SET_UPCOMING_SELF_EMPLOYMENT,
    SET_APPLIED_LOCAL_SERVICES,
    TAB_SUBMITTED, 
    SET_PROGRESS
} from './actionTypes';

let environment = (typeof process.env.NODE_ENV !== 'undefined' && process.env.NODE_ENV === 'production') ? 'production' : 'development';
let API_SERVER = ServerSetting[environment].API_SERVER;

export const setJobseekerProfile = Profile => {
    return {
        type: SET_JOBSEEKER_PROFILE,
        Profile
    };
}
export const setJobseekerAddress = Address => {
    return {
        type: SET_JOBSEEKER_ADDRESSES,
        Address
    };
}
export const setSkillApplied = status => {
    return {
        type: SET_SKILL_APPLIED,
        status
    };
}

export const setProfileTabSubmitted = status => {
    return {
        type: TAB_SUBMITTED,
        status
    };
}

export const setProgress = data => {
    localStorage.setItem('progress', data)
    return {
        type: SET_PROGRESS,
        data
    };
}


export const setOrganizationType = organisation => {
    return {
        type: SET_ORGANIZATION_TYPE,
        organisation
    };
}

export const setMarital = Maritalstatues => {
    return {
        type: SET_MARITAL,
        Maritalstatues
    };
}

export const setSearchResults = results => {
    return {
        type: SET_SEARCH_RESULTS,
        results
    };
}

export const setSelfEmploymentSearchResults = results => {
    return {
        type: SET_SELF_EMPLOYMENT_SEARCH_RESULTS,
        results
    };
}
export const setExperience = results => {
    return {
        type: SET_EXPERIENCE_RESULTS,
        results
    };
}
export const setHallTicket = results => {
    return {
        type: SET_HALL_TICKET,
        results
    };
}


export const setSeekerExperience = Experience => {
    return {
        type: USER_JOBSEEKER_EXPERIENCE,
        Experience
    };
}
export const jobApplied = status => {
    return {
        type: APPLY_FOR_JOB,
        status
    };
}

export const setRelevantSelfEmployment = data => {
    return {
        type: SET_RELEVANT_SELF_EMPLOYMENT,
        data
    };
}
export const getRelevantSelfEmployment = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'selfemployment/relevent-data', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '200') {
                    dispatch(setRelevantSelfEmployment(resp.data.data));
                }
            })
            .catch(error => {
                // console.log(error);

            });
    };
};
// getJoiningDays

// =joiningdate/view


export const postSelfEmploymentSearch = (data) => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'selfemployment/view', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader());
                // console.log('resp.data', resp.data)
                if (resp.data.status !== '401') {
                    dispatch(setSelfEmploymentSearchResults(resp.data.data));
                } else {
                    dispatch(setSelfEmploymentSearchResults(resp.data));
                }
                // dispatch(setSelfEmploymentSearchResults(resp.data.data));
            })
            .catch(error => {
                // console.log(error);
                // // dispatch(Logout());
            });
    };
}

export const applyEvent = (data) => {
    data['auth_key'] = localStorage.getItem('token')
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'apply-event/create', qs.stringify(data))
            .then(resp => {
                if (resp.data.status === '200') {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(get_jobseeker_profile())
                    dispatch(getAppliedEvents())
                } else {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                }
                dispatch(unset_Loader())
            })
            .catch(error => {
                // console.log(error);
                // // dispatch(Logout());
            });
    };
}

export const getExperience = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'jobseeker-experience/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                // console.log(resp.data)
                // debugger
                dispatch(unset_Loader());
                dispatch(setExperience(resp.data));
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
}
export const getHallTicket = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'match-jobseeker/index', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(setHallTicket(resp.data));
            })
            .catch(error => {
                // console.log(error);
                // // dispatch(Logout());
            });
        dispatch(unset_Loader());
    };
}

export const getAllJobByEducation = () => {
    return (dispatch, getState) => {
        const user_id = getState().actionReducer.Loginuser.id;
        dispatch(set_Loader())

        axios.post(API_SERVER + 'search-job/create', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                // console.log('resp', resp)

                let value = resp.data
                if (resp.data.data && resp.data.applied) {
                    value = []
                    resp.data.data.forEach((val) => {
                        const filterValue = resp.data.applied.filter((ap) => user_id === ap.user_id && val.id === ap.job_id)
                        let status = filterValue && filterValue[0] ? true : false
                        value.push({ ...val, status, ...filterValue[0] })
                    })

                }
                dispatch(setAllJobByEducation(value));
                // if (resp.data.status === '401') {
                // } else {
                //     dispatch(setAllJobByEducation(resp.data));
                // }
                dispatch(unset_Loader())
            })
            .catch(error => {

                // dispatch(Logout());
            });
    };
};

export const setAllJobByEducation = jobs => {
    return {
        type: SET_ALL_JOB_BY_EDU,
        jobs
    };
}


export const willGoForInterview = (data) => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'accepted-record/view', qs.stringify(data))
            .then(resp => {
                // dispatch(setHallTicket(resp.data));
                if (resp.data.status === '200') {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    // dispatch(getAppliedJobs())
                    dispatch(getScheduledJobs())
                } else {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                }
                dispatch(unset_Loader());
            })
            .catch(error => {
                // console.log(error);
                // // dispatch(Logout());
            });

    };
}


export const updateSearchSetting = (data) => {
    // console.log('data', data)
    // data['auth_key'] = 
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'update-usertype/update', qs.stringify(data))
            .then(resp => {
                // console.log('resp', resp)
                if (resp.data.status === '200') {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(get_jobseeker_profile())
                    // dispatch()
                } else {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                }

            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
        dispatch(unset_Loader());
    };
}



export const sendFeedback = (data) => {
    data['auth_key'] = localStorage.getItem('token')
    return dispatch => {
        dispatch(set_Loader());
        // http://151.106.28.89/ggr/api/v1/index.php/
        axios.post(API_SERVER + 'apply-counselling/update', qs.stringify(data))
            .then(resp => {
                // dispatch(setHallTicket(resp.data));
                if (resp.data.status === '200') {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                }
                dispatch(unset_Loader());
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });

    };
}
export const getHallTicketData = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'apply-job-mela/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                if (resp.data.status === '200') {
                    dispatch(setHallTicketForPrint(resp.data.data));
                } else {
                    dispatch(setHallTicketForPrint({notFound: true}));
                }
            })
            .catch(error => {
                // console.log(error);

            });
        dispatch(unset_Loader());
    };
}

export const getJobMelaEmployers = (data, mergeData = false) => {
    data['auth_key'] = localStorage.getItem('token')
    return (dispatch, getState) => {
        axios.post(API_SERVER + 'match-jobseeker/index', qs.stringify(data))
            .then(resp => {
                if (resp.data.status === '200') {
                    if(mergeData){
                        let oldData = getState().JobseekerReducer.hallTicket.data;
                        let newData = oldData.concat(resp.data.data)
                        resp.data.data = newData
                        dispatch(setHallTicket(resp.data));
                    } else {
                        dispatch(setHallTicket(resp.data));
                    }
                    dispatch(unset_Loader())
                } else { 
                    dispatch(setHallTicket(resp.data));
                }
            })
            .catch(error => {
                // console.log(error);
            });
        dispatch(unset_Loader())
    };
};


export const applyJob = (data, setApplied = true) => {
    data.auth_key = localStorage.getItem('token')
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'apply-job/index', qs.stringify(data))
            .then(resp => {
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    if (setApplied) {
                        dispatch(jobApplied(true))
                    }
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                }
                dispatch(unset_Loader());
            })
            .catch(error => {
                // dispatch(Logout());
            });
    };
};

export const applyForgienJob = (data) => {
    data.auth_key = localStorage.getItem('token')
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'apply-foreign-job/index', qs.stringify(data))
            .then(resp => {
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(jobApplied(true))
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                }
                dispatch(unset_Loader());
            })
            .catch(error => {
                // // dispatch(Logout());
            });
    };
};

export const applyForgienStudy = (data) => {
    data.auth_key = localStorage.getItem('token')
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'apply-foreign-study/index', qs.stringify(data))
            .then(resp => {
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(jobApplied(true))
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                }
                dispatch(unset_Loader());
            })
            .catch(error => {
                // dispatch(Logout());
            });
    };
};

export const postJobInterviewParticipation = (data) => {
    // console.log(data)
    data.auth_key = localStorage.getItem('token')
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'job-invitation/index', qs.stringify(data))
            .then(resp => {

                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(getJobInvites())
                }
                dispatch(unset_Loader());
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const postForeignJobInterviewParticipation = (data) => {
    // console.log(data)
    data.auth_key = localStorage.getItem('token')
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'invite-foreign-candidates/create', qs.stringify(data))
            .then(resp => {
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(getForeignJobsInterview())
                }
                dispatch(unset_Loader());
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const addUpdateExperience = (data) => {
    // console.log(data)
    data.auth_key = localStorage.getItem('token')
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'jobseeker-experience/create', qs.stringify(data))
            .then(resp => {
                // console.log(resp)
                // debugger
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    // dispatch(getJobInvites())
                }
                dispatch(unset_Loader());
            })
            .catch(error => {
                // console.log(error);
                // // dispatch(Logout());
            });
    };
};


export const setJobMelaResults = results => {
    return {
        type: SET_JOB_MELA_RESULTS,
        results
    };
}

export const setJobMela = results => {
    return {
        type: SET_JOB_MELA,
        results
    };
}
export const setHallTicketForPrint = results => {
    return {
        type: HALL_TICKET_PRINT,
        results
    };
}
export const postJobMela = (data) => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'jobmela/view', qs.stringify(data))
            .then(resp => {

                dispatch(unset_Loader());
                dispatch(setJobMelaResults(resp.data));
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const getAllJobMelas = () => {
    return async dispatch => {
        const allMelas = axios.post(API_SERVER + 'job-melas/index').then(async resp => {
            return resp.data
        })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
        const appliedMelas = axios.post(API_SERVER + 'job-mela/index', qs.stringify({ auth_key: localStorage.getItem('token') })).then(async resp => {
            return resp.data
        })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
        Promise.all([allMelas, appliedMelas]).then(function (values) {
            const finalMelas = []
            values[0].forEach((item) => {
                let melas = values[1] && values[1].length ? values[1] : []
                let indexOfMela = melas.findIndex((applied) => applied.mela_id === item.id)
                melas = indexOfMela !== -1 ? values[1][indexOfMela] : ''
                finalMelas.push(Object.assign({}, item, melas))
            })
            dispatch(setJobMela(finalMelas));
        }).catch(error => {
            // console.log(error);
            // dispatch(Logout());
        });
    };

};



export const setRecommendedJobs = jobs => {
    return {
        type: SET_RECOMMENDED_JOBS,
        jobs
    };
}

export const setSelfEmploymentById = data => {
    return {
        type: SET_SELF_EMPLOYMENT_BY_ID,
        data
    };
}


export const getRecommendedJobs = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'postjobsrecommended/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {

                dispatch(unset_Loader());
                dispatch(setRecommendedJobs(resp.data));
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};
export const getAppliedJobMela = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'recommendedjobmela/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {

                dispatch(unset_Loader());
                dispatch(setAppliedPages(resp.data));
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};
export const getSelfEmployment = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'appliedselfemployment/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {

                dispatch(unset_Loader());
                if (resp.data.status === '200') {
                    dispatch(setAppliedPages(resp.data.data));
                }

            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const getUpcomingSelfEmployment = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.get(API_SERVER + 'upcoming-selfemployment/index')
            .then(resp => {
                // console.log('resp', resp)
                dispatch(unset_Loader());
                if (resp.data.status !== '401') {
                    dispatch(setUpcomingSelfEmployment(resp.data));
                }

            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

// http://151.106.28.89/ggr/api/v1/index.php/

export const getSelfEmploymentById = (id) => {
    return dispatch => {
        dispatch(set_Loader());
        // http://151.106.28.89/ggr/api/v1/index.php/selfemployment/index
        axios.post(API_SERVER + 'selfemployment/index', qs.stringify({ id, auth_key: localStorage.getItem('token') }))
            .then(resp => {

                dispatch(unset_Loader());
                // console.log('resp', resp.data)
                // if(resp.data && resp.data.length){
                if (resp.data.status === '200') {
                    dispatch(setSelfEmploymentById(resp.data.data));
                } else {
                    dispatch(setSelfEmploymentById({ "notFound": true }));
                }

                // } else {
                //     dispatch(push('/404'))
                // }
                // if(resp.data.status !== '401'){
                //     dispatch(setSelfEmploymentById(resp.data));
                // }

            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const getAppliedJobs = () => {
    return dispatch => {
        // dispatch(setAppliedPages([]))
        dispatch(set_Loader());
        axios.post(API_SERVER + 'applied-job/index', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader());
                dispatch(setAppliedJobs(resp.data));
            })
            .catch(error => {
                // console.log(error);
                // // dispatch(Logout());
            });
    };
};

export const getAppliedEvents = () => {
    return dispatch => {
        // dispatch(setAppliedPages([]))
        dispatch(set_Loader());
        // http://92.42.110.29/ggr/api/v1/index.php/
        axios.post(API_SERVER + 'apply-event/index', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '200') {
                    dispatch(setAppliedEvents(resp.data.rows));
                }

            })
            .catch(error => {
                // console.log(error);
                // // dispatch(Logout());
            });
    };
};




export const getAppliedForgienJobs = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'apply-foreign-job/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader());
                dispatch(setAppliedPages(resp.data));
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const getAppliedForgienStudy = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'apply-foreign-study/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader());
                // console.log('resp', resp)
                if (resp.data.status !== '401') {
                    dispatch(setAppliedPages(resp.data.rows));
                }

            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const getAppliedServices = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'apply-services/index', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader());
                console.log('resp', resp.data)
                if (resp.data.status !== '401') {
                    dispatch(setAppliedServices(resp.data.data));
                }

            })
            .catch(error => {
                // console.log(error);

            });
    };
};


export const getAppliedCounsellings = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'appliedcounselling/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {

                dispatch(unset_Loader());
                dispatch(setAppliedPages(resp.data));
            })
            .catch(error => {
                // console.log(error);

            });
    };
};
export const getAppliedSkills = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'appliedskill/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {

                dispatch(unset_Loader());
                dispatch(setAppliedPages(resp.data));
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};


export const setAppliedServices = data => {
    return {
        type: SET_APPLIED_LOCAL_SERVICES,
        data
    };
}
export const setAppliedPages = pages => {
    return {
        type: SET_APPLIED_PAGES,
        pages
    };
}

export const setUpcomingSelfEmployment = data => {
    return {
        type: SET_UPCOMING_SELF_EMPLOYMENT,
        data
    };
}



export const setRecommendedSkills = skills => {
    return {
        type: SET_RECOMMENDED_SKILLS,
        skills
    };
}

export const setAppliedJobs = data => {
    return {
        type: SET_JOBS_APPLIED,
        data
    };
}

export const setAppliedEvents = data => {
    return {
        type: SET_APPLIED_EVENTS,
        data
    };
}


// setAppliedJobs

export const getRecommendedSkills = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'recommendedskill/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader());
                dispatch(setRecommendedSkills(resp.data));
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};
export const setRecommendedMelas = melas => {
    return {
        type: SET_RECOMMENDED_MELAS,
        melas
    };
}

export const getRecommendedMelas = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'recommendedjobmela/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader());
                dispatch(setRecommendedMelas(resp.data));
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const setRecommendedCounselling = counselling => {
    return {
        type: SET_RECOMMENDED_COUNSELLING,
        counselling
    };
}

export const getRecommendedCounselling = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'recommendedcounselling/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {

                dispatch(unset_Loader());
                dispatch(setRecommendedCounselling(resp.data));
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};
export const setScheduledCareerCounselling = counselling => {
    return {
        type: SET_CAREER_COUNSELLING,
        counselling
    };
}

export const setScheduledForeignJobs = data => {
    return {
        type: SET_SCHEDULED_FOREIGN_JOBS,
        data
    };
}

export const setScheduledStudy = data => {
    return {
        type: SET_SCHEDULED_STUDY,
        data
    };
}

export const setScheduledSelfEmployment = data => {
    return {
        type: SET_SCHEDULED_SELF_EMPLOYMENT,
        data
    };
}



export const getScheduledStudy = () => {
    return dispatch => {
        dispatch(set_Loader());
        // http://151.106.28.89/ggr/api/v1/index.php/search-foreign-study/view
        axios.post(API_SERVER + 'search-foreign-study/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader());
                // console.log('getScheduledStudy', resp)
                if (resp.data.status !== '401') {
                    dispatch(setScheduledStudy(resp.data));
                }

            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const setCounsellingSearchByArea = data => {
    return {
        type: SET_COUNSELLOR_SEARCH_RESULT,
        data
    };
}

export const postCounsellingSearchByArea = (data) => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'searchcounselling/view-interest', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader());
                let value = []
                if (resp.data.status === '401') {
                    value = resp.data
                } else {
                    value = resp.data.data
                }

                dispatch(setCounsellingSearchByArea(value));
            })
            .catch(error => {
                // console.log(error);

            });
    };
};

export const setPsdmSkillProgram = data => {
    return {
        type: SET_PSDM_SKILL_PROGRAM,
        data
    };
}

export const postPsdmSkillProgram = (data) => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'skill-status/create', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader());
                let value = []
                if (resp.data.status === '401') {
                    value = resp.data
                } else {
                    value = resp.data.data
                }

                dispatch(setPsdmSkillProgram(value));
            })
            .catch(error => {
                // console.log(error);

            });
    };
};

export const getScheduledCareerCounselling = () => {
    return dispatch => {
        dispatch(set_Loader());

        axios.post(API_SERVER + 'scheduled-counselling-session/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status !== '401') {
                    dispatch(setScheduledCareerCounselling(resp.data.data));
                }
            })
            .catch(error => {
                // console.log(error);

            });
    };
};

export const getForeignJobsInterview = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'invite-foreign-candidates/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader());
                // console.log('resp 232', resp.data)
                if (resp.data.status !== '401') {
                    dispatch(setForeignJobsInterview(resp.data.rows));
                } else {
                    dispatch(setForeignJobsInterview([]));
                }
            })
            .catch(error => {
                // console.log(error);

            });
    };
};


export const getScheduledSelfEmployment = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'appliedselfemployment/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '200') {
                    dispatch(setScheduledSelfEmployment(resp.data.data));
                }
            })
            .catch(error => {
                // console.log(error);

            });
    };
};
export const setScheduledJobMela = jobMela => {
    return {
        type: SET_SCHEDULED_JOB_MELA,
        jobMela
    };
}

export const setForeignJobsInterview = data => {
    return {
        type: SET_FOREIGN_JOBS_INTERVIEW,
        data
    };
}


export const getScheduledJobMela = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'scheduled-job-mela/index', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader());
                dispatch(setScheduledJobMela(resp.data));
            })
            .catch(error => {
                // console.log(error);
                // // dispatch(Logout());
            });
    };
};

export const postSkillSearch = (data) => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'skillpost/view', qs.stringify(data))
            .then(resp => {

                dispatch(unset_Loader());
                dispatch(setSkillSearch(resp.data));
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const getScheduledForeignJobs = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'search-foreign-job/create', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status !== '401') {
                    dispatch(setScheduledForeignJobs(resp.data));
                }

                // console.log('create', resp)
            })
            .catch(error => {
                // console.log(error);

            });
    };
};

export const postCounsellingSearch = (data, auth = false) => {
    if (auth) {
        data['auth_key'] = localStorage.getItem('token')
    }
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'searchcounselling/view', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader());
                let value = []
                if (resp.data.status === '401') {
                    value = resp.data
                } else {
                    resp.data.data.forEach((val) => {
                        let filterValue = resp.data.applied.filter((ap) => val.id === ap.session_id)
                        filterValue = filterValue && filterValue[0] ? filterValue[0] : []
                        value.push({ ...val, ...filterValue })
                    })
                }

                dispatch(setCounsellingResult(value));
            })
            .catch(error => {
                // console.log(error);

            });
    };
};


export const setCounsellingResult = results => {
    return {
        type: SET_COUNSELLING_RESULTS,
        results
    };
}

export const setSkillSearch = results => {
    return {
        type: SET_SKILL_SEARCH,
        results
    };
}

export const sendChatInvite = (data, searchData, searchType = "") => {
    data['auth_key'] = localStorage.getItem('token')
    console.log('data', data)
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'request-search/create', qs.stringify(data))
            .then(resp => {
                if (resp.data.status === '200') {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    if (data.user_type === "6") {
                        if (searchType === 1) {
                            dispatch(getSearchResults(searchData, false))
                        } else {
                            dispatch(getAdvanceSearchResults(searchData))
                        }
                    } else if (data.user_type === "7") {
                        dispatch(searchForgienJobs(searchData))
                    } else if (data.user_type === "10") {
                        dispatch(postCounsellingSearch(searchData, true))
                        dispatch(push('/myaccount'))
                    } else if (data.user_type === "12") {
                        dispatch(searchForgienStudy(searchData))
                    }
                } else {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                }
                dispatch(unset_Loader())
            })
            .catch(error => { });
    };
}

export const setUserProfileInterest = data => {
    return {
        type: SET_USER_PROFILE_INTEREST,
        data
    };
};

export const getUserProfileInterest = () => {
    return dispatch => {
        axios.post(API_SERVER + 'user-profile/view-interest', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '200') {
                    dispatch(setUserProfileInterest(resp.data.rows))
                }
            })
            .catch(error => {
                // console.log('error',error)

            });
    };
}

export const addUserProfileInterest = (data) => {
    data['auth_key'] = localStorage.getItem('token')
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'user-profile/add-interest', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '200') {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(getUserProfileInterest())
                } else {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                }
            })
            .catch(error => {
                // console.log('error',error)

            });
    };
}

export const applyForeignCounselling = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'apply-counselling/preference', qs.stringify({
            auth_key: localStorage.getItem('token'),
            
        }))
            .then(resp => {
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(getForeignCounsellings())
                }
                dispatch(unset_Loader());
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
}

export const setForeignCounsellings = data => {
    return {
        type: SET_FOREIGN_COUNSELLING,
        data
    };
}


export const getForeignCounsellings = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'apply-counselling/get-preference', qs.stringify({
            auth_key: localStorage.getItem('token'),
            
        }))
            .then(resp => {
                if (resp.data.status === '200') {
                    dispatch(setForeignCounsellings(resp.data))
                }
                dispatch(unset_Loader());
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
}

export const getSearchResults = (data, locationParse = true) => {

    const formData = {
        education_level: data.education_level,
        experience: data.experience,
        job_type: data.job_type ? data.job_type : '1',
        job_title: data.job_title,
        gender:data.gender ? data.gender: undefined,
        pwd: parseInt(data.pwd) ? data.pwd : undefined,
        job_title: data.job_title
    }
    if (data.location && data.location.length && locationParse) {
        formData['location'] = JSON.parse(data.location)
    }
    if (data.location && data.location.length && !locationParse) {
        const locationsIds = []
        data.location.forEach((loc) => {
            locationsIds.push(loc.id)
        })
        formData['location'] = locationsIds
    }
    // if(data['techType']){
    //     formData['techType'] = data['techType']
    // }
    if (data['govtType']) {
        formData['govtType'] = data['govtType']
    }
    // console.log('formData', formData)
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'search-job/index', qs.stringify(formData))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status !== '401') {
                    dispatch(setSearchResults(resp.data.data));
                } else {
                    dispatch(setSearchResults(resp.data));
                }

            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const getAdvanceSearchResults = (data) => {

    const formData = {
        govtType: data.govtType,
        techType: data.techType,
        education: data.education,
        experience: data.experience,
        job_type: data.job_type ? data.job_type : '1',
        desired_salary: data.desired_salary,
        exp: data.exp,
        age: data.age,
        qualification: data.qualification,
        auth_key: localStorage.getItem('token'),
    }
    if (data.preferred_place && data.preferred_place.length) {
        const locationsIds = []
        data.preferred_place.forEach((loc) => {
            locationsIds.push(loc.id)
        })
        formData['preferred_place'] = locationsIds
    }
    return (dispatch, getState) => {

        dispatch(set_Loader());
        const user_id = getState().actionReducer.Loginuser.id;
        axios.post(API_SERVER + 'search-job/view', qs.stringify(formData))
            .then(resp => {
                dispatch(unset_Loader());
                let value = []
                if (resp.data.status === '401') {
                    value = resp.data
                } else {
                    if (resp.data.data) {
                        value = []
                        if (resp.data.applied && resp.data.applied.length) {
                            resp.data.data.forEach((val) => {
                                const filterValue = resp.data.applied.filter((ap) => user_id === ap.user_id && val.id === ap.job_id)
                                let status = filterValue && filterValue[0] ? true : false
                                value.push({ ...val, status, ...filterValue[0] })
                            })
                        } else {
                            value = resp.data.data
                        }
                    }
                }
                dispatch(setSearchResults(value));
            }).catch(error => {
                // console.log(error);
                // // dispatch(Logout());
            });
    };
};



export const searchForgienJobs = (data) => {
    return (dispatch, getState) => {
        dispatch(set_Loader());
        const user_id = getState().actionReducer.Loginuser.id;
        axios.post(API_SERVER + 'search-foreign-job/view', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status !== '401') {
                    let value = []
                    if (Object.keys(resp.data).length > 1) {
                        resp.data.data.forEach((val) => {
                            let filterValue = resp.data.applied.filter((ap) => user_id === ap.user_id && val.id === ap.job_id)
                            filterValue = filterValue && filterValue[0] ? filterValue[0] : []
                            value.push({ ...val, ...filterValue })
                        })
                        dispatch(setSearchResultsForgeignJobs(value));
                    }
                } else {
                    dispatch(setSearchResultsForgeignJobs({ ...resp.data, message: "No Records Found" }));
                }


            })
            .catch(error => {
                // console.log(error);

            });
    };
};

export const setSearchResultsForgeignJobs = results => {
    return {
        type: SET_SEARCH_FOREIGN_JOB_RESULTS,
        results
    };
}

export const searchForgienJobsHomepage = (data) => {
    return (dispatch) => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'search-foreign-job/index', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader());
                dispatch(setSearchResultsForgeignJobs(resp.data));

            })
            .catch(error => {
                // console.log(error);
                // 
            });
    };
};

export const searchForgienStudy = (data) => {
    return (dispatch, getState) => {
        dispatch(set_Loader());
        // const user_id = getState().actionReducer.Loginuser.id;
        axios.post(API_SERVER + 'search-foreign-study/index', qs.stringify(data))
            .then(resp => {
                // console.log('resp', resp)
                dispatch(unset_Loader());
                dispatch(setSearchResults(resp.data));
                // dispatch(jobApplied(true))
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};






export const setReligion = Religionstatues => {
    return {
        type: SET_RELIGION,
        Religionstatues
    };
}
export const setMessage = msg => {
    return {
        type: SET_MESSAGE,
        msg
    };
}
export const setStatus = status => {
    return {
        type: SET_STATUS,
        status
    };
}

export const changePassword = (auth_key, password, current_password) => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'site/reset-password', qs.stringify({
            auth_key,
            password,
            current_password
        }))
            .then(resp => {
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setMessage(resp.data.message))
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                }
                dispatch(unset_Loader());
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
}
export const applyJobMela = applyJobs => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'apply-job-mela/index', qs.stringify({
            auth_key: localStorage.getItem('token'),
            applyJobs
        }))
            .then( resp => {
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    // await dispatch(setHallTicketForPrint([]))
                    dispatch(push('/hall-ticket'))
                }
                dispatch(unset_Loader());
            })
            .catch(error => {
                // console.log(error);

            });
    };
}

export const removeJobApplied = (id, params) => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'apply-job-mela/delete', qs.stringify({
            auth_key: localStorage.getItem('token'),
            applyJobs: id
        }))
            .then(async resp => {
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    await dispatch(setNotification({ type: "success", message: resp.data.message }));
                    await dispatch(setHallTicket([]))
                    dispatch(getJobMelaEmployers(params));
                }
                dispatch(unset_Loader());
            })
            .catch(error => {
                // console.log(error);

            });
    };
}



export const applySelfEmployment = employment_id => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'apply-self-employment/index', qs.stringify({
            auth_key: localStorage.getItem('token'),
            employment_id
        }))
            .then(resp => {
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                }
                dispatch(unset_Loader());
                dispatch(push('/myaccount'))
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
}
export const applyForCounselling = session_id => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'apply-counselling/index', qs.stringify({
            auth_key: localStorage.getItem('token'),
            session_id
        }))
            .then(resp => {
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(setApplyForCounselling(true))
                }
                dispatch(unset_Loader());
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
}


export const setMarginMoney = money => {
    return {
        type: SET_MARGIN_MONEY,
        money
    };
}

export const setApplyForCounselling = status => {
    return {
        type: APPLY_FOR_COUNSELLING,
        status
    };
}

export const getMarginMoney = () => {
    return dispatch => {
        axios.get(API_SERVER + 'margin-money/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {

                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setMarginMoney(resp.data));
                }
            })
            .catch(error => {
                // console.log(error);
                // // dispatch(Logout());
            });
    };
};


export const setCounsellingById = data => {
    return {
        type: SET_COUNSELLING_BY_ID,
        data
    };
}
export const getCounsellingById = (id) => {
    return dispatch => {
        // console.log('id', id)

        axios.post(API_SERVER + 'searchcounselling/index', qs.stringify({ id, auth_key: localStorage.getItem('token') }))
            .then(resp => {
                if (resp.data.status === '200') {
                    dispatch(setCounsellingById(resp.data.rows));
                } else {
                    dispatch(setCounsellingById({ "notFound": true }));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};



export const searchCareer = (data) => {
    // data['auth_key'] = localStorage.getItem('token')
    return dispatch => {
        axios.post(API_SERVER + 'career/view', qs.stringify(data))
            .then(resp => {
                // console.log('resp', resp.data)
                if (resp.data.status === '200') {
                    dispatch(setCareer(resp.data.data));
                }
                else {
                    dispatch(setCareer(resp.data));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};





export const setScheduledJobs = jobs => {
    return {
        type: SET_SCHEDULED_JOBS,
        jobs
    };
}
export const setCareer = data => {
    return {
        type: SET_CAREER,
        data
    };
}


export const getScheduledJobs = () => {
    return dispatch => {
        axios.post(API_SERVER + 'scheduled-job/index', qs.stringify({ auth_key: localStorage.getItem('token') }))
            // axios.post(API_SERVER + 'scheduled-job/view', qs.stringify({auth_key: localStorage.getItem('token')}))
            .then(resp => {
                if (resp.data.status === '401') {
                    // dispatch(setNotification({type:"error",message:resp.data.message}));
                } else {
                    dispatch(setScheduledJobs(resp.data));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const setJobInvites = invites => {
    return {
        type: SET_JOB_INVITES,
        invites
    };
}

export const sendServiceFeedback = (data) => {

    return (dispatch) => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'feedback-form/create', qs.stringify(data))
            .then(resp => {
                if (resp.data.status === '200') {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                }
                dispatch(unset_Loader());
            })
            .catch(error => {
                // console.log(error);

            });

    };
}

export const getJobInvites = () => {
    return dispatch => {
        axios.post(API_SERVER + 'job-invitation/index', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                if (resp.data.status === '401') {
                    // dispatch(setNotification({type:"error",message:resp.data.message}));
                } else {
                    dispatch(setJobInvites(resp.data));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};


export const setUpcomingJobs = jobs => {
    return {
        type: SET_UPCOMING_JOBS,
        jobs
    };
}

export const getUpcomingJobs = () => {
    return dispatch => {
        axios.post(API_SERVER + 'upcoming-jobs/index', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                if (resp.data.status === '401') {
                    // dispatch(setNotification({type:"error",message:resp.data.message}));
                } else {
                    dispatch(setUpcomingJobs(resp.data));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const setUpcomingSkills = skills => {
    return {
        type: SET_UPCOMING_SKILLS,
        skills
    };
}

export const getUpcomingSkills = () => {
    return dispatch => {
        axios.post(API_SERVER + 'upcoming-skills/index', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {

                if (resp.data.status === '401') {
                    // dispatch(setNotification({type:"error",message:resp.data.message}));
                } else {
                    dispatch(setUpcomingSkills(resp.data));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const setJoinByDays = days => {
    return {
        type: SET_JOIN_BY_DAYS,
        days
    };
}

export const getJoinByDays = () => {

    return dispatch => {
        axios.get(API_SERVER + 'joiningdate/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {

                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setJoinByDays(resp.data));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
}

export const setScheduledSkills = skills => {
    return {
        type: SET_SCHEDULED_SKILLS,
        skills
    };
}
export const getScheduledSkills = () => {

    return dispatch => {
        axios.post(API_SERVER + 'scheduled-skill/index', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {

                if (resp.data.status === '401') {
                    // dispatch(setNotification({type:"error",message:resp.data.message}));
                } else {
                    dispatch(setScheduledSkills(resp.data));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
}

export const applyJobSkill = (skill_id) => {

    return dispatch => {
        axios.post(API_SERVER + 'apply-skill/index', qs.stringify({
            auth_key: localStorage.getItem('token'),
            skill_id
        }))
            .then(resp => {

                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(setSkillApplied(true))
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
}

export const setSubsidy = subsidy => {
    return {
        type: SET_SUBSIDY,
        subsidy
    };
}

export const setAmount = amount => {
    return {
        type: SET_AMOUNT,
        amount
    };
}

export const getSubsidy = () => {
    return dispatch => {
        axios.get(API_SERVER + 'subsidy/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {

                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setSubsidy(resp.data));
                }
            })
            .catch(error => {
                // console.log(error);
                // // dispatch(Logout());
            });
    };
};

export const setCounsellingType = counsellingType => {
    return {
        type: SET_COUNSELLING_TYPE,
        counsellingType
    };
}

export const getCounsellingType = () => {
    return dispatch => {
        axios.get(API_SERVER + 'counsellingtype/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {

                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setCounsellingType(resp.data));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};


export const getAmount = () => {
    return dispatch => {
        axios.get(API_SERVER + 'amount/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {

                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setAmount(resp.data));
                }
            })
            .catch(error => {
                // console.log(error);
                // // dispatch(Logout());
            });
    };
};

export const setTickets = data => {
    return {
        type: SET_TICKETS,
        data
    };
}
export const setIssueTypes = data => {
    return {
        type: SET_ISSUE_TYPES,
        data
    };
}

export const createTicket = (data) => {
    data['auth_key'] = localStorage.getItem('token')
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'crm/index', qs.stringify(data))
            .then(resp => {
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(getTickets())
                }
                dispatch(unset_Loader());
            })
            .catch(error => { });
    };
}

export const getMatchingCareers = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'career/match', qs.stringify({
            auth_key: localStorage.getItem('token')
        }))
            .then(resp => {
                if (resp.data.status === '401') {
                    dispatch(setMatchingCareers([]));
                } else {
                    dispatch(setMatchingCareers(resp.data.data));
                }
                dispatch(unset_Loader());
            })
            .catch(error => {
                // console.log(error);

            });
    };
}

export const setMatchingCareers = data => {
    return {
        type: SET_SELF_EMP_CAREERS,
        data
    };
}

export const getTickets = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'crm/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                if (resp.data.status === '200') {
                    dispatch(setTickets(resp.data.data));
                } else {
                    dispatch(setTickets([]));
                }
                dispatch(unset_Loader());
            })
            .catch(error => { });
    };
}

export const getIssueTypes = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.get(API_SERVER + 'issue-type/view')
            .then(resp => {
                if (resp.data.status === '200') {
                    dispatch(setIssueTypes(resp.data.data));
                }

                dispatch(unset_Loader());
            })
            .catch(error => { });
    };
}

export const getOrganizationType = () => {
    return dispatch => {
        axios.get(API_SERVER + 'ggr-organization-type/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setOrganizationType(resp.data));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const setCategory = Categorystatues => {
    return {
        type: SET_CATEGORY,
        Categorystatues
    };
}
export const setRelation = Relationstatues => {
    return {
        type: SET_RELATION,
        Relationstatues
    };
}
export const setBloodGroups = BloodGroups => {
    return {
        type: SET_BLOOD_G,
        BloodGroups
    };
}

export const setEyeSight = EyeSight => {
    return {
        type: SET_EYESIGHT,
        EyeSight
    };
}

export const setPhysical = Physical => {
    return {
        type: SET_JOBSEEKER_PHYSICAL,
        Physical
    };
}

export const setDisability = Disability => {
    return {
        type: SET_DISABILITY,
        Disability
    };
}
export const setCapacity = Capacity => {
    return {
        type: SET_CAPACITY,
        Capacity
    };
}
export const setEducation = Educations => {
    return {
        type: USER_JOBSEEKER_EDUCATIONS,
        Educations
    };
}
export const setJobmelaAttached = (e_type, message) => {
    return {
        type: SET_JOB_MELA_ATTECH,
        e_type,
        message
    };
}
export const setAdditionalSkills = (skills) => {
    return {
        type: SET_ADDITIONAL_SKILLS, skills
    };
}

export const addInterestInSelfEmp = () => {
    return (dispatch) => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'selfemployment/add-interest', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader());
                dispatch(getUserSelfEmpInterest());

            })
            .catch(error => {
                // console.log(error);
                // 
            });
    };
};

export const getUserSelfEmpInterest = () => {
    return (dispatch) => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'selfemployment/view-interest', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {

                dispatch(unset_Loader());
                dispatch(setUserSelfEmpInterest(resp.data.interest));
            })
            .catch(error => {
                // console.log(error);
                // 
            });
    };
};

export const setUserSelfEmpInterest = status => {
    return {
        type: SET_SELF_EMP_INTEREST,
        status
    };
}
export const get_jobseekerEducations = () => {
    return dispatch => {
        axios.post(API_SERVER + 'jobseeker-education/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setEducation(resp.data));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};
export const get_jobseekerPhysical = () => {
    return dispatch => {
        axios.post(API_SERVER + 'ggr-jobseeker-physical-attributes/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setPhysical(resp.data));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const getAdditionalSkills = (id = null) => {
    return dispatch => {
        dispatch(set_Loader())
        axios.post(API_SERVER + 'docs-upload/view', qs.stringify({
            auth_key: localStorage.getItem('token'),
            id
        }))
            .then(resp => {
                if (!id) {
                    const data = resp.data.length > 0 ? resp.data : []
                    dispatch(setAdditionalSkills(data)); //Set
                }
                if (id) {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                }
                dispatch(unset_Loader())
            })
            .catch(error => {
                // console.log(error);
                // // dispatch(Logout());
            });
    };
};

export const addAdditionalSkill = (data) => {
    var formData = new FormData();
    // data.forEach((prop, key) => formData[key] = prop)
    return (dispatch, getState) => {
        const user_id = getState().actionReducer.Loginuser.id;
        formData.set("img", data.img ? data.img : '');
        formData.set("skill_name", data.skill_id ? data.skill_id : '');
        formData.set("others", data.others ? data.others : '');
        formData.set("id", data.id ? data.id : '');
        formData.set("skill_duration", data.skill_duration ? data.skill_duration : '');
        formData.set("passing_year", data.passing_year ? data.passing_year : '');
        formData.set("institution_name", data.institution_name ? data.institution_name : '');
        formData.set("user_id", user_id);
        formData.set("auth_key", data.auth_key);
        dispatch(set_Loader())
        axios.post(API_SERVER + 'docs-upload/index', formData, { headers: { 'content-type': 'multipart/form-data' } })
            .then(resp => {
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(getAdditionalSkills())
                }
                dispatch(unset_Loader())
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const get_jobseekerAddress = () => {
    return dispatch => {
        axios.post(API_SERVER + 'ggr-address/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setJobseekerAddress(resp.data));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const postTrainingInterest = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'user-profile/add-training', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '200') {
                    dispatch(getTrainingInterest())
                }
            })
            .catch(error => { });
    };
}

export const getTrainingInterest = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'user-profile/view-training', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '200') {
                    dispatch(setTrainingInterest(resp.data.interest));
                }
            })
            .catch(error => { });
    };
}

export const setTrainingInterest = status => {
    return {
        type: SET_TRAINING_INTEREST,
        status
    };
}

export const get_jobseeker_profile = () => {
    return dispatch => {
        dispatch(set_Loader())
        axios.post(API_SERVER + 'ggr-jobseeker-profile/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
              
                    dispatch(setJobseekerProfile(resp.data));
                }
                dispatch(unset_Loader())
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};
export const getMarital = () => {
    return dispatch => {
        axios.get(API_SERVER + 'ggr-marital-status/view')
            .then(resp => {
                dispatch(setMarital(resp.data));
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const enableChat = data => {

    const newData = {
        auth_key: localStorage.getItem('token'),
        chat_id: data.chat_id
    }

    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'request-search/chat-enable', qs.stringify(newData))
            .then(resp => {
                if (resp.data.status === '200') {
                    dispatch(push(data.url))
                }
                dispatch(unset_Loader());
            })
            .catch(error => {
                // console.log(error);
            });
    };
}

export const getCapacity = () => {
    return dispatch => {
        axios.get(API_SERVER + 'ggr-working-capacity/view')
            .then(resp => {
                dispatch(setCapacity(resp.data));
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};
export const getEyeSight = () => {
    return dispatch => {
        axios.get(API_SERVER + 'ggr-e/view')
            .then(resp => {
                dispatch(setEyeSight(resp.data));
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};
export const getDisability = () => {
    return dispatch => {
        axios.get(API_SERVER + 'ggr-user-disablity-type/view')
            .then(resp => {
                dispatch(setDisability(resp.data));
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};
export const getBloodGroups = () => {
    return dispatch => {
        axios.get(API_SERVER + 'ggr-user-blood-group/view')
            .then(resp => {
                dispatch(setBloodGroups(resp.data));
            })
            .catch(error => {
                // console.log(error);
            });
    };
};
export const getReligion = () => {
    return dispatch => {
        axios.get(API_SERVER + 'ggr-user-religion/view')
            .then(resp => {
                dispatch(setReligion(resp.data));
            })
            .catch(error => {
                // console.log(error);
            });
    };
};
export const getCategory = () => {
    return dispatch => {
        axios.get(API_SERVER + 'ggr-user-reservation-category/view')
            .then(resp => {
                dispatch(setCategory(resp.data));
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};
export const getRelation = () => {
    return dispatch => {
        axios.get(API_SERVER + 'ggr-guardian-relation/view')
            .then(resp => {
                dispatch(setRelation(resp.data));
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};
export const get_update_address = (data) => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'ggr-address/create', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(get_jobseekerAddress());
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

// export const get_update_profile = (data) => {
//     return dispatch => {
//         dispatch(set_Loader());
//         axios.post(API_SERVER + 'ggr-jobseeker-profile/create', qs.stringify(data))
//             .then(resp => {
//                 dispatch(unset_Loader());
//                 if (resp.data.status === '401') {
//                     dispatch(setNotification({ type: "error", message: resp.data.message }));
//                 } else {
//                     if (data.hasOwnProperty("flashMsg") && !data.flashMsg) {
//                         dispatch(setNotification({ type: "success", message: resp.data.message }));
//                     }
//                     dispatch(get_jobseeker_profile());
//                 }
//             })
//             .catch(error => {
//                 // console.log(error);
//                 // dispatch(Logout());
//             });
//     };
// };
export const get_update_profile = (data) => {
    return async dispatch => {
        dispatch(set_Loader());

        if (data.image && typeof data.image === 'object') {
            await dispatch(get_update_profile_image(data.image))
        } else {
            delete data['image']
        }
        if (data.doc && typeof data.doc === 'object') {
            await dispatch(get_update_profile_image(data.doc))
        } else {
            delete data['doc']
        }

        axios.post(API_SERVER + 'ggr-jobseeker-profile/create', qs.stringify(data))
            .then(async resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    if (data.hasOwnProperty("flashMsg") && !data.flashMsg) {
                        dispatch(setNotification({ type: "success", message: resp.data.message }));
                    }
                    await dispatch(setProfileTabSubmitted('profile'))
                    setTimeout(() => { dispatch(get_jobseeker_profile()) }, 2000)
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};
export const subscribeForWebinar = (data, refirect = false) => {
    data['auth_key'] = localStorage.getItem('token')
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'subscription/view', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader());
                console.log('resp', resp)
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    if (refirect) {
                        dispatch(push('/apply-webinar'))
                    }
                    dispatch(get_jobseeker_profile())
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};
export const setSchduledServices = data => {
    return {
        type: SET_SCHEDULED_SERVICE,
        data
    };
}

export const getSchduledServices = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'schedule-service/index', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '200') {
                    dispatch(setSchduledServices(resp.data.data));
                }

            })
            .catch(error => {
                // console.log(error);

            });
    };
}

export const get_update_mela_docs = (image, education_type) => {
    return (dispatch, getState) => {
        const user_id = getState().actionReducer.Loginuser.id;
        var formData = new FormData();
        formData.set("img", image);
        formData.set("user_id", user_id);
        formData.set("education_type", education_type);
        formData.set("auth_key", localStorage.getItem('token'));
        axios.post(API_SERVER + 'update-image/view', formData, { headers: { 'content-type': 'multipart/form-data' } })
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setJobmelaAttached(education_type, resp.data[0].doc_path));
                }
            })
            .catch(error => {
                // console.log(error);
            });
    };
};

export const addQualificationForJobMela = (data) => {
    return (dispatch) => {
        var formData = new FormData();
        formData.set("img", data.img);
        formData.set("percentage", data.percentage);
        formData.set("education_type", data.education_type);
        formData.set("auth_key", localStorage.getItem('token'));
        dispatch(set_Loader())
        axios.post(API_SERVER + 'docs-upload/create', formData, { headers: { 'content-type': 'multipart/form-data' } })
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(getOrDeleteQualification())
                }
                dispatch(unset_Loader())
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const setQualification = qualification => {
    return {
        type: SET_QUALIFICATION,
        qualification
    };
}

export const getOrDeleteQualification = (id = null) => {
    return dispatch => {
        dispatch(set_Loader())
        axios.post(API_SERVER + 'document/view', qs.stringify({
            auth_key: localStorage.getItem('token'),
            id
        }))
            .then(resp => {
                if (!id) {
                    const data = resp.data.length > 0 ? resp.data : []
                    dispatch(setQualification(data)); //Set
                }
                if (id) {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                }
                dispatch(unset_Loader())
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const get_update_profile_image = (image) => {
    return (dispatch, getState) => {
        dispatch(set_Loader());
        const user_id = getState().actionReducer.Loginuser.id;
        var formData = new FormData();
        formData.set("img", image);
        formData.set("user_id", user_id);

        axios.post(API_SERVER + 'update-image/create', formData, { headers: { 'content-type': 'multipart/form-data' } })
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(get_jobseeker_profile());
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const get_delete_Education = (data) => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'jobseeker-education/delete', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(get_jobseekerEducations());
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const setSeekerEducation = education => {
    return {
        type: SET_SEEKER_EDUCATION,
        education
    };
}

export const setSeekerPercentage = percentage => {
    return {
        type: SET_SEEKER_PERCENTAGE,
        percentage
    };
}

export const expUpdated = status => {
    return {
        type: EXP_UPDATED,
        status
    };
}

export const getSeekerEducationForJobMela = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'document/index', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '401') {
                    // dispatch(setNotification({type:"error",message:resp.data.message}));
                } else {
                    // dispatch(setNotification({type:"success",message:resp.data.message}));
                    dispatch(setSeekerEducation(resp.data));
                    dispatch(unset_Loader());
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const getSeekerPercentageForJobMela = (data) => {
    return dispatch => {
        dispatch(set_Loader());
        axios.get(API_SERVER + 'academic-percentage/index')
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '401') {
                    // dispatch(setNotification({type:"error",message:resp.data.message}));
                } else {
                    // dispatch(setNotification({type:"success",message:resp.data.message}));
                    dispatch(setSeekerPercentage(resp.data));
                    dispatch(unset_Loader())
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};
export const get_update_Education = (data) => {
    const formData = new FormData();
    formData.set("img", data.img ? data.img : '');
    formData.set("id", data.id ? data.id : '');
    formData.set("score_type", data.score_type);
    formData.set("specilization_id", data.specilization_id ? data.specilization_id : '');
    formData.set("specilization_other", data.specilization_other ? data.specilization_other : '');

    formData.set("education_level_id_value", data.education_level_id_value ? data.education_level_id_value : '');
    formData.set("education_level_id", data.education_level_id ? data.education_level_id : '');
    formData.set("percentage", data.percentage ? data.percentage : '');
    formData.set("percentile", data.percentile ? data.percentile : '');
    formData.set("grade", data.grade ? data.grade : '');
    formData.set("education_level_others", data.education_level_others ? data.education_level_others : '');
    formData.set("Univ_board_other", data.Univ_board_other ? data.Univ_board_other : '');
    formData.set("highest_education_level", data.highest_education_level ? data.highest_education_level : '');
    formData.set("course_language_id", data.course_language_id ? data.course_language_id : '');
    formData.set("Univ_board_value", data.Univ_board_value ? data.Univ_board_value : '');
    formData.set("score_type", data.score_type ? data.score_type : '');
    formData.set("Univ_board", data.Univ_board ? data.Univ_board : '');
    formData.set("passing_year", data.passing_year);
    formData.set("auth_key", data.auth_key);
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'jobseeker-education/create', formData, { headers: { 'content-type': 'multipart/form-data' } })
            // axios.post(API_SERVER + 'jobseeker-education/create', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(get_jobseekerEducations());
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};
export const get_update_Physical = (data) => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'ggr-jobseeker-physical-attributes/create', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(get_jobseekerPhysical());
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const get_delete_Experience = (data) => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'jobseeker-experience/delete', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(getExperience());
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const get_jobseekerExperience = () => {
    return dispatch => {
        dispatch(set_Loader())
        axios.post(API_SERVER + 'jobseeker-experience/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setSeekerExperience(resp.data));
                }
                dispatch(unset_Loader())
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const get_update_Experience = (data) => {
    data['key_skills'] = data.key_skills && data.key_skills.length ? data.key_skills : null
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'jobseeker-experience/create', qs.stringify(data))
            .then(resp => {

                dispatch(unset_Loader());
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    // dispatch(expUpdated(true))
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(getExperience());
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};


export const set_job_mela_register = (data) => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'job-melas/create', qs.stringify(data))
            .then(resp => {

                dispatch(unset_Loader());
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    // dispatch(get_jobseekerExperience());
                }
            })
            .catch(error => {
                // console.log(error);
                // // dispatch(Logout());
            });
    };
};

export const getAdvanceServicessSearchResults = (data) => {
    return (dispatch, getState) => {
        dispatch(set_Loader());
        // const user_id = getState().actionReducer.Loginuser.id;
        axios.post(API_SERVER + 'search-service/index', qs.stringify(data))
            .then(resp => {
                // console.log('getAdvanceServicessSearchResults ... -> '+JSON.stringify(resp.data));  

                dispatch(unset_Loader());
                if (resp.data.status !== '401') {
                    let value = []
                    if (Object.keys(resp.data).length > 0) {
                        resp.data.forEach((val) => {
                            //console.log('getAdvanceServicessSearchResults ... -> '+JSON.stringify(val));  
                            //const filterValue = resp.data.applied.filter((ap) => user_id === ap.user_id && val.id === ap.job_id)
                            //let status = filterValue && filterValue[0] ? true: false
                            value.push({ ...val })
                        })
                        dispatch(setSearchResults(value));
                    }
                } else {
                    dispatch(setSearchResults(resp.data));
                }


            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const contactService = (data) => {
    data.auth_key = localStorage.getItem('token')
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'apply-services/index', qs.stringify(data))
            .then(resp => {
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(jobApplied(true))
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                }
                dispatch(unset_Loader());
            })
            .catch(error => {
                // dispatch(Logout());
            });
    };
};
import React, { Component } from 'react';
import {
    Router,
    Route,
    browserHistory,
    Redirect,
    IndexRoute
} from 'react-router';

import IdleTimer from 'react-idle-timer'
import { Logout } from './actions/accountAction'
import store from './store'

import Home from './components/Home';
import Signup from './components/signup/Signup';
import SignIn from './components/login/SignIn';
import Resetpass from './components/login/Resetpass';
import VerifyUser from './components/login/VerifyUser'

import AdvanceSearch from './components/AdvanceSearch';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import LocalServicePage from './components/LocalServicePage';
import JobFairEmployers from './components/dashboard/JobFairEmployers'
import JobSeekersPage from './components/JobSeekersPage';
import GuestPage from './components/GuestPage';
import EmployerPage from './components/EmployerPage';
import ForeignEmployementPage from './components/ForeignEmployementPage';
import ChangePassword from './components/dashboard/ChangePassword'
import CopyRight from './components/dashboard/CopyRight'
import Privacy from './components/dashboard/Privacy'
import Faq from './components/dashboard/Faq'
import NoticeForFraud from './components/dashboard/NoticeForFraud'
// import MobilizationDrive from './components/dashboard/MobilizationDrive'
import ForeignStudyCamp from './components/dashboard/ForeignStudyCamp'
import RateEnquiry from './components/dashboard/RateEnquiry'
// import ForgeinJobs from './components/dashboard/ForgeinJobs'
import SelfEmpSectors from './components/CareerOpportunities';

import GovtJobs from './components/dashboard/GovtJobs'
import Services from './components/dashboard/Services'
import Employment from './components/dashboard/Employment'
import Help from './components/dashboard/Help'
import Sitemap from './components/dashboard/Sitemap'
import SelfEmployment from './components/dashboard/SelfEmployment'
// import Labour from './components/dashboard/Labour'
import ForeignInfo from './components/dashboard/ForeignModuleInfo'

import Webinar from "./components/dashboard/Webinar";
import Downloads from "./components/dashboard/Downloads"
import FreeTraining from "./components/FreeTraining";
import CounsellingSessions from './components/dashboard/CounsellingSessions'

import CounsellingProviderAccount from './components/dashboard/counsellingprovider/MyAccount'
import CounsellingProviderProfile from './components/dashboard/counsellingprovider/MyProfile'
import PostCounselling from './components/dashboard/counsellingprovider/PostCounselling'
import SessionCalendar from './components/dashboard/counsellingprovider/SessionCalendar'
import AppliedCandidates from './components/dashboard/counsellingprovider/AppliedCandidates'
import SessionFeedback from './components/dashboard/counsellingprovider/SessionFeedback'


import PrivateJobs from './components/dashboard/PrivateJobs'
import UserManual from './components/dashboard/UserManual'
import HowToUse from './components/dashboard/jobseeker/HowToUse'
import ApplyWebinar from './components/dashboard/jobseeker/ApplyWebinar';
import Forum from './components/dashboard/jobseeker/Forum';
// import AppliedJobFair from './components/dashboard/jobseeker/AppliedJobFair'
// import HallTicket from './components/dashboard/jobseeker/HallTicket'
import SearchCareer from './components/dashboard/jobseeker/SearchCareer'
import ForeignCounsellingDetails from './components/dashboard/jobseeker/ForeignCounsellingDetails'
// import HowToUseProvider from './components/dashboard/jobprovider/HowToUseProvider'
import PortalFeedback from './components/dashboard/PortalFeedback'
import MobileSignIn from './components/login/MobileSignIn'
import JobDetailPage from './components/dashboard/JobDetailPage'
import ScreenRecorder from './components/dashboard/ScreenRecorder'
import JobDetailHomePage from './components/JobDetailHomePage'
import SearchResults from './components/dashboard/SearchResults'

import Jobsearch from './components/dashboard/jobseeker/Jobsearch';
import AllInvites from './components/dashboard/AllInvites';
import AllJobsForUser from './components/dashboard/jobseeker/AllJobsForUser';
import MyAccount from './components/dashboard/jobseeker/MyAccount';
import Myprofile from './components/dashboard/jobseeker/Myprofile';
import SearchSelfEmployment from './components/dashboard/jobseeker/SearchSelfEmployment'
import AppliedJobs from './components/dashboard/jobseeker/AppliedJobs'
import Interest from './components/dashboard/jobseeker/Interest'
import SkillProgram from './components/dashboard/jobseeker/SkillProgram'
import SearchSession from './components/dashboard/jobseeker/SearchSession'
import SearchSessionByArea from './components/dashboard/jobseeker/SearchSessionByArea'
import CounsellingDetails from './components/dashboard/jobseeker/CounsellingDetails'
import JobproviderApplyJob from './components/dashboard/jobprovider/ApplyJob';
import PostJob from './components/dashboard/jobprovider/PostJob';
import Providerprofile from './components/dashboard/jobprovider/Providerprofile';
// import SchduledInterviews from './components/dashboard/jobprovider/SchduledInterviews';
// import Servicesearch from './components/dashboard/jobseeker/Servicesearch';
// import AppliedJobsProvider from './components/dashboard/jobprovider/AppliedJobsProvider';
import CandidatesList from './components/dashboard/jobprovider/CandidatesList';
import SelectedCandidatesList from './components/dashboard/jobprovider/SelectedCandidatesList';
import ConfirmedCandidates from './components/dashboard/jobprovider/ConfirmedCandidates';
// import Search from './components/dashboard/jobprovider/Search';
import auth from './components/hoc/auth'
// import checkForeignStudyProvider from './components/hoc/checkForeignStudyProvider'
import checkCounsellor from './components/hoc/checkCounsellor'
import checkActiveJobMela from './components/hoc/checkActiveJobMela'
import notAuth from './components/hoc/notAuth'
import PageNotFound from "./components/dashboard/PageNotFound";
import AccessDenied from "./components/dashboard/AccessDenied";

// import CreateEvent from './components/dashboard/CreateEvent'
// import Events from './components/dashboard/Events'

import ContactUs from "./components/ContactUs";
// import Videos from "./components/Videos";
import Notification from "./components/Notification"

// import ImageGallery from "./components/ImageGallery";
import Crm from './components/dashboard/jobseeker/Crm';


import Lspaccount from './components/dashboard/localserviceprovider/LspServices';
import Lspprofile from './components/dashboard/localserviceprovider/Providerprofile';
import VisitCalendar from './components/dashboard/localserviceprovider/VisitCalendar';
import ServiceFeedback from './components/dashboard/localserviceprovider/ServiceFeedback';
import AppliedServiceCandidates from './components/dashboard/localserviceprovider/AppliedCandidates';
import LspPostJob from './components/dashboard/localserviceprovider/PostServices';

import aboutus from './components/aboutus';


// Forgien job provider
// import ForgienJobproviderAccount from './components/dashboard/forgienjobprovider/ForgienJobproviderAccount'
// import PostForgienJob from './components/dashboard/forgienjobprovider/PostForgienJob'
// import ForgienProviderTimeline from './components/dashboard/forgienjobprovider/ForgienProviderTimeline'
// import ForgienProviderprofile from './components/dashboard/forgienjobprovider/ForgienProviderprofile'
// import ForeignCandidatesList from './components/dashboard/forgienjobprovider/CandidatesList';
// import SelectedForeignCandidatesList from './components/dashboard/forgienjobprovider/SelectedCandidatesList';
// import ConfirmedForeignCandidates from './components/dashboard/forgienjobprovider/ConfirmedCandidates';
// import SearchForgienJob from './components/dashboard/jobseeker/SearchForgienJob'
// import checkForeignJobProvider from './components/hoc/checkForeignJobProvider'

// import ForgienStudyAccount from './components/dashboard/forgienstudyprovider/MyAccount'
// import AppliedEventCandidates from './components/dashboard/forgienstudyprovider/AppliedEventCandidates'
// import ForgienStudyProviderprofile from './components/dashboard/forgienstudyprovider/MyProfile'
// import PostStudy from './components/dashboard/forgienstudyprovider/PostStudy'
// import AppliedCourseCandidates from './components/dashboard/forgienstudyprovider/AppliedCandidates'
// import ShortlistedCourseCandidates from './components/dashboard/forgienstudyprovider/ShorlistedCandidates'


class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isTimedOut: false
        }
        this.idleTimer = React.createRef()
    }

    _onAction = (e) => {
        this.setState({ isTimedOut: false })
    }

    _onIdle = (e) => {
        const { isTimedOut } = this.state;
        if (isTimedOut && localStorage.getItem('token') && localStorage.getItem('token') !== "null") {
            store.dispatch(Logout())
        }
        else {
            this.idleTimer.current.reset();
            this.setState({ isTimedOut: true })
        }
    }

    render() {
        return (
            <div id="rootContent">
                <IdleTimer
                    key="idleTimer"
                    ref={this.idleTimer}
                    element={document}
                    // onActive={this._onActive}
                    onIdle={this._onIdle}
                    timeout={1000 * 60 * 10} //10 minutes
                />
                <Header route={this.props.location} router={this.props.router} />
                <div
                    className="education-main-section education-letest-newsfull logoimg wow ext-mrg-pd main-content-height">
                    <div className="container-fluid p-0 top-m">
                        {this.props.children}
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

const router = (
    <Router history={browserHistory}>
        <Route path='/' component={App}>
            <IndexRoute component={Home} />
            <Route path='/notification' component={Notification} />
            {/* <Route path="videos" component={Videos} /> */}
            <Route path="downloads" component={Downloads} />
            {/* <Route path="gallery" component={ImageGallery} /> */}
            <Route path='signup' component={notAuth(Signup)} />
            <Route path='/local-services' component={Services} />
            <Route path='signin' component={SignIn} />
            <Route path='resetpassword' component={notAuth(Resetpass)} />
            <Route path='/about' component={JobSeekersPage} />
            <Route path='/guestpage' component={GuestPage} />
            <Route path='/services' component={EmployerPage} />
            <Route path='/foreignemployementpage' component={ForeignEmployementPage} />
            <Route path='/industrylist' component={LocalServicePage} />
            <Route path='/change-password' component={ChangePassword} />
            <Route path='/portal-feedback' component={PortalFeedback} />
            <Route path='/signin-with-otp' component={notAuth(MobileSignIn)} />
            <Route path='/advance-search' component={AdvanceSearch} />
            <Route path='/govt-jobs' component={GovtJobs} />
            <Route path='/private-jobs' component={PrivateJobs} />
            <Route path='/search-results' component={SearchResults} />
            <Route path='/job-details-home/:jobId' component={JobDetailHomePage} />
            <Route path='/contact-us' component={ContactUs} />
            <Route path='/copyright-policy' component={CopyRight} />
            <Route path='/privacy-policy' component={Privacy} />
            <Route path='/faq' component={Faq} />
            <Route path='/fraudulent-cases' component={NoticeForFraud} />
            {/* <Route path='/mobilization-drive' component={MobilizationDrive} /> */}
            <Route path='/foreign-study-campaign' component={ForeignStudyCamp} />
            <Route path='/rate-enquiry/:id' component={RateEnquiry} />
            <Route path='/employment' component={Employment} />
            <Route path='/help' component={Help} />
            <Route path='/sitemap' component={Sitemap} />
            <Route path='/verify-user' component={VerifyUser} />
            <Route path='/screen-reader' component={ScreenRecorder} />
            <Route path='/user-manual' component={UserManual} />
            {/* <Route path='/foreign-jobs' component={ForgeinJobs} /> */}
            <Route path='/career-opportunities' component={SelfEmpSectors} />
            <Route path='/self-employment' component={SelfEmployment} />
            <Route path='/counselling-sessions' component={CounsellingSessions} />
            <Route path='/foreign-module-info' component={ForeignInfo} />
            <Route path='/webinar' component={Webinar} />
            <Route path='/forum' component={Forum} />
            <Route path='/invites' component={AllInvites} />
            <Route path='/free-training' component={FreeTraining} />
            <Route path='/job-placement-fair-employers/:id' component={JobFairEmployers} />
            <Route path='/aboutus' component={aboutus} />


            {localStorage.getItem('login_type') === 'Jobseeker' &&
                <Route path='/'>
                    <Route path='/myaccount' component={auth(MyAccount)} />
                    <Route path='/crm' component={auth(Crm)} />
                    <Route path='/jobsearch' component={auth(Jobsearch)} />
                    <Route path='/apply-webinar' component={auth(ApplyWebinar)} />
                    <Route path='/jobs' component={auth(AllJobsForUser)} />
                    <Route path='/myprofile' component={auth(Myprofile)} />
                    <Route path="/applied/jobs" component={auth(AppliedJobs)} />
                    <Route path="/how-to-use" component={auth(HowToUse)} />
                    <Route path='/job-details/:jobId' component={auth(JobDetailPage)} />
                    <Route path='/interest' component={auth(Interest)} />
                    <Route path="/counselling-details/:id" component={auth(CounsellingDetails)} />
                    <Route path="/psdm-skill-program" component={auth(SkillProgram)} />
                    {/* <Route path='/jobfair' component={auth(checkActiveJobMela(AppliedJobFair))} /> */}
                    {/* <Route path="/hall-ticket" component={auth(checkActiveJobMela(HallTicket))} /> */}
                    <Route path='/search-counselling-session' component={auth(SearchSession)} />
                    {/* <Route path='/forgien-jobs' component={auth(SearchForgienJob)} /> */}
                    <Route path='/search-career' component={auth(SearchCareer)} />
                    <Route path='/search-self-employment' component={auth(SearchSelfEmployment)} />
                    <Route path='/search-counselling-session-by-area' component={auth(SearchSessionByArea)} />
                    <Route path='/foreign-counselling-details' component={auth(ForeignCounsellingDetails)} />
                </Route>
            }
            {localStorage.getItem('login_type') === 'Indian Employer' &&
                <Route path='/'>
                    <Route path='/myaccount' component={auth(JobproviderApplyJob)} />
                    <Route path='/post-job' component={auth(PostJob)} />
                    <Route path='/myprofile' component={auth(Providerprofile)} />
                    <Route path='/applied-candidates/:job_id' component={auth(CandidatesList)} />
                    <Route path='/shortlisted-candidates/:job_id' component={auth(SelectedCandidatesList)} />
                    <Route path='/selected-candidates/:job_id' component={auth(ConfirmedCandidates)} />
                </Route>
            }
            {localStorage.getItem('login_type') === 'Counselling Provider' &&
                <Route path='/'>
                    <Route path='/myaccount' component={auth(checkCounsellor(CounsellingProviderAccount))} />
                    <Route path='/myprofile' component={auth(CounsellingProviderProfile)} />
                    <Route path='/post-counselling' component={auth(checkCounsellor(PostCounselling))} />
                    <Route path="/session-feedback" component={auth(SessionFeedback)} />
                    <Route path="/session-calendar" component={auth(SessionCalendar)} />
                    <Route path='/applied-candidates/:id' component={auth(checkCounsellor(AppliedCandidates))} />
                </Route>
            }

             {localStorage.getItem('login_type') === 'Local Service Provider' &&
                <Route path='/'>
                    <Route path='/myaccount' component={auth(Lspaccount)} />
                    <Route path='/post-service' component={auth(LspPostJob)} />
                    <Route path='/myprofile' component={auth(Lspprofile)} />
                    <Route path='/visit-calendar' component={auth(VisitCalendar)} />
                    <Route path='/service-feedback' component={auth(ServiceFeedback)} />
                    <Route path='/applied-candidates/:id' component={auth(AppliedServiceCandidates)} />
                </Route>
            }
            
            {/*
            {localStorage.getItem('login_type') === 'Foreign job Provider' &&
                <Route path='/'>
                    <Route path='/myaccount' component={auth(checkForeignJobProvider(ForgienJobproviderAccount))} />
                    <Route path='/post-job' component={auth(checkForeignJobProvider(PostForgienJob))} />
                    <Route path='/myprofile' component={auth(ForgienProviderprofile)} />
                    <Route path='/timeline' component={auth(ForgienProviderTimeline)} />
                    <Route path='/applied-candidates/:job_id' component={auth(ForeignCandidatesList)} />
                    <Route path='/shortlisted-candidates/:job_id' component={auth(SelectedForeignCandidatesList)} />
                    <Route path='/selected-candidates/:job_id' component={auth(ConfirmedForeignCandidates)} />

                </Route>
            } */}
            {/* {localStorage.getItem('login_type') === 'Foreign Study Provider' &&
                <Route path='/'>
                    <Route path='/applied-candidates/:id' component={auth(checkForeignStudyProvider(AppliedCourseCandidates))} />
                    <Route path='/shorlisted-candidates/:id' component={auth(checkForeignStudyProvider(ShortlistedCourseCandidates))} />
                    <Route path='/myaccount' component={auth(checkForeignStudyProvider(ForgienStudyAccount))} />
                    <Route path='/myprofile' component={auth(ForgienStudyProviderprofile)} />
                    <Route path='/post-study' component={auth(checkForeignStudyProvider(PostStudy))} />
                    <Route path='/create-event' component={auth(checkForeignStudyProvider(CreateEvent))} />
                    <Route path='/events' component={auth(checkForeignStudyProvider(Events))} />
                    <Route path='/event-candidates/:id' component={auth(checkForeignStudyProvider(AppliedEventCandidates))} />
                </Route>
            } */}
            <Route path='/404' component={PageNotFound} />
            <Route path='/access-denied' component={AccessDenied} />

            <Redirect from='*' to='/404' />
        </Route>
    </Router>
);

export default router;

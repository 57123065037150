import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { Link } from 'react-router';
import {
    get_jobseeker_profile,
    // getHallTicketData
} from '../../../actions/jobseekerAction'
// import {get_jobseeker_profile} from '../../../actions/jobseekerAction'
import store from '../../../store'
import ServerSetting from '../../../config.json';
import { MDBProgress } from 'mdbreact';
// const SKILL_PROVIDER_ID = "4"
// const COUNSELLING_PROVIDER_ID = "5"
// const FORGIEN_JOB_PROVIDER_ID = "2"
// const FORGIEN_STUDY_PROVIDER_ID = "11"

class Sidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            basePath: window.location.protocol + '//' + window.location.host + '/'
        }

    }

    componentDidMount() {
        window.openCloseSidebar()
        if (this.props.JobseekerReducer.Profile && !Object.keys(this.props.JobseekerReducer.Profile).length) {
            store.dispatch(get_jobseeker_profile());
        }
        // if (this.props.JobseekerReducer.hallTicketData && !this.props.JobseekerReducer.hallTicketData.length) {
        //     store.dispatch(getHallTicketData())
        // }
    }

    getColor = () => {
        let progress = this.props.route.path === '/myprofile' ?
            this.props.JobseekerReducer.progress :
            parseInt(localStorage.getItem('progress'))
        if (progress === 20) return 'danger'
        if (progress === 40) return 'warning'
        if (progress === 60) return 'info'
        if (progress === 80) return 'success'
        if (progress === 100) return 'success'
    }

    handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value }, () => {
            this.validateField(name, value)
        });
    }


    changeRegister = (event) => {
        window.location.href = this.state.basePath + 'signup?type=' + event;
    }

    capitalizeFirstLetter = (string) => {
        if (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }

    render() {
        let Mainprops = this.props;
        const SERVER_PATH = ServerSetting[process.env.NODE_ENV].SERVER_PATH;
        // let isSkillSearch = ''
        // let isCounsellingSearch = ''
        // let isForgienSearch = ''
        // let isForgienStudySearch = ''
        // if (this.props.JobseekerReducer.Profile && this.props.JobseekerReducer.Profile.subtype_id) {
        //     const usertype = this.props.JobseekerReducer.Profile.subtype_id.split(",")
        //     // isSkillSearch = usertype.filter((user) => user === SKILL_PROVIDER_ID)[0]
        //     isCounsellingSearch = usertype.filter((user) => user === COUNSELLING_PROVIDER_ID)[0]
        //     isForgienSearch = usertype.filter((user) => user === FORGIEN_JOB_PROVIDER_ID)[0]
        //     isForgienStudySearch = usertype.filter((user) => user === FORGIEN_STUDY_PROVIDER_ID)[0]
        // }

        let colClass = Mainprops.route.path === '/jobfair' ? 'col-md-2 px-0 widget-sidebar' : 'col-md-3 px-0 widget-sidebar'

        let progress = Mainprops.route.path === '/myprofile' ?
            this.props.JobseekerReducer.progress :
            parseInt(localStorage.getItem('progress'))
        
        return (
            <aside className={colClass} id="left">
                <div className="list-group w-100">
                    <ul className="nav flex-column flex-nowrap ">

                        <Link to="/myaccount" className="date-clr">
                            <div className="d-flex align-items-center justify-content-center w-100 pull-left pt-2 outr-profile text-center">
                                <div className="outr-avtar">
                                    <img alt="avatar"
                                        src={this.props.JobseekerReducer.Profile.image ? `${SERVER_PATH}${this.props.JobseekerReducer.Profile.image}` : '/images/img-1.png'}
                                        className="avatar mr-2 text-center" />
                                </div>
                                <div className="mb-2 font-18">
                                    <span className="outr-profile-text">
                                        {this.props.t('login.hi', { param: 'react' }).trimEnd()}, {this.capitalizeFirstLetter(this.props.JobseekerReducer.Profile.firstname)}</span>
                                    <br></br>{this.props.t('dashboard.WelcomeDashboard', { param: 'react' })}
                                </div>
                                {progress ? <div style={{ width: "80%" }}>
                                    <MDBProgress className="my-2" material
                                        value={progress}
                                        height="10px"
                                        color={this.getColor()} />
                                    <div className="mb-2">
                                        <span className="date-clr">
                                            {this.props.t('profileDetails.completed', { param: 'react' })} {progress} %
                                        </span></div>
                                </div> : null}
                            </div>
                        </Link>


                        <li data-placement="bottom"
                            title={this.props.t('sidebar.profile_detailsDesc', { param: 'react' })}
                            className={"nav-item list-group-item " + (Mainprops.route.path === '/myprofile' ? 'active' : '')}>
                            <Link className="nav-link"
                                to="/myprofile"><b>{this.props.t('sidebar.profile_details', { param: 'react' })}</b>
                            </Link></li>

                            {/* <li data-placement="bottom"
                                title="Artificial Intelligence and Data Science upskilling course"
                                className={"nav-item list-group-item " + (Mainprops.route.path === '/psdm-skill-program' ? 'active' : '')}>
                                <Link className="nav-link"
                                    to="/psdm-skill-program"><b>Artificial Intelligence and Data Science Upskilling Course <img src={"/images/BLINK.gif"} alt={"New"}/> </b>
                                </Link></li> */}

                            {/* {this.props.actionReducer.activeJobMela && this.props.actionReducer.activeJobMela.hasOwnProperty('expire') && !this.props.actionReducer.activeJobMela.expire ?
                            <> */}
                                {/* <li data-placement="bottom"
                                    title={this.props.t('sidebar.mela', { param: 'react' })}
                                    className={"nav-item list-group-item " + (Mainprops.route.path === '/jobfair' ? 'active' : '')}>
                                    <Link className="nav-link"
                                        to="/jobfair"><b>{this.props.actionReducer.activeJobMela.jobMela}</b>
                                        <img src={"/images/BLINK.gif"} alt={"New"} />
                                    </Link></li> 

                                <li data-placement="bottom"
                                    title={this.props.t('hallTicket.desc', { param: 'react' })}
                                    className={"nav-item list-group-item " + (Mainprops.route.path === '/hall-ticket' ? 'active' : '')}>
                                    <Link className="nav-link"
                                        to="/hall-ticket"><b>{this.props.t('hallTicket.sidebar', { param: 'react' })}</b></Link></li> */}
                            {/* </>
                            : null} */}

                            <li data-placement="bottom"
                            title={this.props.t('interest.titleDesc', { param: 'react' })}
                            className={"nav-item list-group-item " + (Mainprops.route.path === '/interest' ? 'active' : '')}>
                            <Link className="nav-link"
                                to="/interest"><b>{this.props.t('interest.title', { param: 'react' })}</b> </Link>
                        </li>



                        <li data-placement="bottom"
                            title={this.props.t('sidebar.search_jobsDesc', { param: 'react' })}
                            className={"nav-item list-group-item " + (Mainprops.route.path === '/jobsearch' ? 'active' : '')}>
                            <Link className="nav-link"
                                to="/jobsearch"><b>{this.props.t('sidebar.moreJobs', { param: 'react' })}</b> </Link>
                        </li>

                        {/* <li data-placement="bottom"
                            title={this.props.t('sidebar.search_forgien_jobsDesc', { param: 'react' })}
                            className={"nav-item list-group-item " + (Mainprops.route.path === '/forgien-jobs' ? 'active' : '')}>
                            <Link className="nav-link"
                                to="/forgien-jobs"><b>{this.props.t('sidebar.forJobs', { param: 'react' })}</b> </Link>
                        </li> */}

                        <li
                            title={this.props.t('sidebar.search_counsellingDesc', { param: 'react' })}
                            className={"nav-item  list-group-item " + (Mainprops.route.path === '/search-counselling-session-by-area' ? 'active' : '')}>
                            <Link className="nav-link"
                                to="/search-counselling-session-by-area"><b>{this.props.t('counsellingProvider.searchCounsellor', { param: 'react' })}</b> </Link>
                        </li>
                        <li
                            title={this.props.t('sidebar.search_counsellingDesc', { param: 'react' })}
                            className={"nav-item  list-group-item " + (Mainprops.route.path === '/search-counselling-session' ? 'active' : '')}>
                            <Link className="nav-link"
                                to="/search-counselling-session"><b>{this.props.t('counsellingProvider.dashboadTitle', { param: 'react' })}</b> </Link>
                        </li>

                        <li data-placement="bottom"
                            title={this.props.t('crm.titleDesc', { param: 'react' })}
                            className={"nav-item list-group-item " + (Mainprops.route.path === '/crm' ? 'active' : '')}>
                            <Link className="nav-link"
                                to="/crm"><b>{this.props.t('crm.title', { param: 'react' })}</b> </Link>
                        </li>
                        {/* <li data-placement="bottom"
                            title={this.props.t('sidebar.search_careerDesc', { param: 'react' })}
                            className={"nav-item list-group-item " + (Mainprops.route.path === '/search-career' ? 'active' : '')}>
                            <Link className="nav-link"
                                to="/search-career"><b>{this.props.t('sidebar.searchCareerOpportunities', { param: 'react' })}</b> </Link>
                        </li> */}
                        <li
                            title={this.props.t('sidebar.searchEmpDesc', { param: 'react' })}
                            className={"nav-item list-group-item " + (Mainprops.route.path === '/search-self-employment' ? 'active' : '')}>
                            <Link className="nav-link"
                                to="/search-self-employment"><b>{this.props.t('homeContent.selfEmp', { param: 'react' })}</b> </Link>
                        </li>

                        <li data-placement="bottom"
                            title="Click here for Forum"
                            className={"nav-item list-group-item " + (Mainprops.route.path === '/forum' ? 'active' : '')}>
                            <Link className="nav-link"
                                to="/forum"><b>
                                    Discussion Forum</b> </Link>
                        </li>


                        {/* <li data-placement="bottom"
                            title={this.props.t('homeContent.webinarDesc', { param: 'react' })}
                            className={"nav-item list-group-item " + (Mainprops.route.path === '/apply-webinar' ? 'active' : '')}>
                            <Link className="nav-link"
                                to="/apply-webinar"><b>{this.props.t('homeContent.webinar', { param: 'react' })}</b><img src={"/images/BLINK.gif"} alt={"New"} /></Link></li> */}

                        {/* {isForgienSearch && isForgienSearch === FORGIEN_JOB_PROVIDER_ID.toString() ?
                            <li data-placement="bottom"
                                title={this.props.t('sidebar.search_forgien_jobsDesc', { param: 'react' })}
                                className={"nav-item list-group-item " + (Mainprops.route.path === '/forgien-jobs' ? 'active' : '')}>
                                <Link className="nav-link"
                                    to="/forgien-jobs"><b>{this.props.t('sidebar.search_forgien_jobs', { param: 'react' })}</b> </Link>
                            </li>
                            : ''}
                        {isCounsellingSearch && isCounsellingSearch === COUNSELLING_PROVIDER_ID.toString() ?
                            <li
                                title={this.props.t('sidebar.search_counsellingDesc', { param: 'react' })}
                                className={"nav-item  list-group-item " + (Mainprops.route.path === '/search-counselling-session' ? 'active' : '')}>
                                <Link className="nav-link"
                                    to="/search-counselling-session"><b>{this.props.t('sidebar.search_counselling', { param: 'react' })}</b> </Link>
                            </li>

                            : ''}
                        {isForgienStudySearch && isForgienStudySearch === FORGIEN_STUDY_PROVIDER_ID.toString() ?
                            <li
                                title={this.props.t('sidebar.searchStudyDesc', { param: 'react' })}
                                className={"nav-item list-group-item " + (Mainprops.route.path === '/search-forgien-study' ? 'active' : '')}>
                                <Link className="nav-link"
                                    to="/search-forgien-study"><b>{this.props.t('jobSearch.searchStudy', { param: 'react' })}</b> </Link>
                            </li> : ''} */}
                        {/* <li
                            title={this.props.t('sidebar.searchEmpDesc', { param: 'react' })}
                            className={"nav-item list-group-item " + (Mainprops.route.path === '/search-self-employment' ? 'active' : '')}>
                            <Link className="nav-link"
                                to="/search-self-employment"><b>{this.props.t('sidebar.searchEmp', { param: 'react' })}</b> </Link>
                        </li>
                        <li data-placement="bottom"
                            title={this.props.t('sidebar.search_lspDesc', { param: 'react' })}
                            className={"nav-item list-group-item " + (Mainprops.route.path === '/servicesearch' ? 'active' : '')}>
                            <Link className="nav-link"
                                to="/servicesearch"><b>{this.props.t('sidebar.search_service', { param: 'react' })}</b> </Link>
                        </li>
                        <li data-placement="bottom"
                            title={this.props.t('sidebar.search_careerDesc', { param: 'react' })}
                            className={"nav-item list-group-item " + (Mainprops.route.path === '/search-career' ? 'active' : '')}>
                            <Link className="nav-link"
                                to="/search-career"><b>{this.props.t('sidebar.search_career', { param: 'react' })}</b> </Link>
                        </li>
                        <li data-placement="bottom"
                            title={this.props.t('sidebar.search_trainingDesc', { param: 'react' })}
                            className={"nav-item list-group-item"}>
                            <a className="nav-link"
                                rel="noopener noreferrer"
                                target="_blank"
                                href="http://psdm.webappline.com/"><b>{this.props.t('sidebar.search_training', { param: 'react' })}
                                </b> </a>
                        </li> */}
                        {/* <li data-placement="bottom"
                            title={this.props.t('sidebar.mela', { param: 'react' })}
                            className={"nav-item list-group-item " + (Mainprops.route.path === '/jobfair' ? 'active' : '')}>
                            <Link className="nav-link"
                                to="/jobfair"><b>{this.props.t('sidebar.job_mela', { param: 'react' })}</b>
                                  <img src={"/images/BLINK.gif"} alt={"New"} />
                            </Link></li> */}
                        {/* {this.props.JobseekerReducer.hallTicketData && this.props.JobseekerReducer.hallTicketData.length > 0 &&
                            <li data-placement="bottom"
                                title={this.props.t('hallTicket.desc', { param: 'react' })}
                                className={"nav-item list-group-item " + (Mainprops.route.path === '/hall-ticket' ? 'active' : '')}>
                                <Link className="nav-link"
                                    to="/hall-ticket"><b>Vacancies Applied in 6th State Level Mega Job Fair</b></Link></li>
                        } */}

                    </ul>
                </div>
            </aside>
        );
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(translate(Sidebar));

import axios from 'axios';
import qs from 'qs';
import ServerSetting from '../config.json';
import {
    SET_CATEGORY, SET_LOADER, JOB_SHIFT, SALARY_TYPE, USER_GENDER, USER_COURSENATURE, SET_JOBPROVIDER_PROFILE,
    UNSET_LOADER, USER_LANGUAGE, USER_QUALIFICATION, USER_SPECILIZATION, USER_INSTITUTION, USER_JOBSEEKER_CITIES,
    USER_JOBSEEKER_SUBDISTRICT, USER_CITIES, USER_SUBDISTRICT, OPEN_OPT, USER_RESET, USER_SUB_TYPE, USER_DISTRICT,
    USER_VERIFIED, GET_LOGIN, CREATE_USER, SET_NOTIFICATION, ORG_TYPE, ORG_CATEGORY, ORG_STATE, FUNCTIONAL_AREA, SECTOR,
    SET_USER_COURSENATURE, JOB_POSTED
} from './actionTypes';
import {setJobMela} from "./jobseekerAction";

let environment = (typeof process.env.NODE_ENV !== 'undefined' && process.env.NODE_ENV === 'production') ? 'production' : 'development';
let API_SERVER = ServerSetting[environment].API_SERVER;
export const setLoginuser = loginuser => {
    return {
        type: GET_LOGIN,
        loginuser
    };
};
export const set_gender = Genders => {
    return {
        type: USER_GENDER,
        Genders
    };
};
export const setJobProviderProfile = Profile => {
    return {
        type: SET_JOBPROVIDER_PROFILE,
        Profile
    };
}
export const set_usersubtype = UserSubType => {
    return {
        type: USER_SUB_TYPE,
        UserSubType
    };
};

export const set_Loader = Education => {
    return {
        type: SET_LOADER,
    };
};
export const set_job_shift = jobShift => {
    return {
        type: JOB_SHIFT,
        jobShift
    };
};
export const unset_Loader = Education => {
    return {
        type: UNSET_LOADER,
    };
};

export const set_JUser_Cities = Cities => {
    return {
        type: USER_JOBSEEKER_CITIES,
        Cities
    };
}
export const set_JUser_SubDistrict = Subdistrict => {
    return {
        type: USER_JOBSEEKER_SUBDISTRICT,
        Subdistrict
    };
};
export const set_User_Cities = Cities => {
    return {
        type: USER_CITIES,
        Cities
    };
}
export const set_Salary_Type = salaryType => {
    return {
        type: SALARY_TYPE,
        salaryType
    };
}
export const set_User_SubDistrict = Subdistrict => {
    return {
        type: USER_SUBDISTRICT,
        Subdistrict
    };
};
export const set_User_District = District => {
    return {
        type: USER_DISTRICT,
        District
    };
};
export const set_userverified = user => {
    return {
        type: USER_VERIFIED,
        user
    };
};
export const set_userreset = user => {
    return {
        type: USER_RESET,
        user
    };
};
export const set_OTP = user => {
    return {
        type: OPEN_OPT,
        user
    };
};
export const set_RegisterUser = user => {
    return {
        type: CREATE_USER,
        user
    };
};
export const set_User_Qualification = Qualification => {
    return {
        type: USER_QUALIFICATION,
        Qualification
    };
};
export const set_User_Institution = Institution => {
    return {
        type: USER_INSTITUTION,
        Institution
    };
};
export const set_User_Specilization = Specilization => {
    return {
        type: USER_SPECILIZATION,
        Specilization
    };
};
export const set_User_Coursenature = Coursenature => {
    return {
        type: USER_COURSENATURE,
        Coursenature
    };
};
export const set_Coursenature = Coursenature => {
    return {
        type: SET_USER_COURSENATURE,
        Coursenature
    };
};
export const set_User_Language = Language => {
    return {
        type: USER_LANGUAGE,
        Language
    };
};


export const set_org_type = Orgtype => {
    return {
        type: ORG_TYPE,
        Orgtype
    };
};
export const set_org_category = Orgcategory => {
    return {
        type: ORG_CATEGORY,
        Orgcategory
    };
};
export const set_org_state = Orgstate => {
    return {
        type: ORG_STATE,
        Orgstate
    };
};
export const set_functional_area = Functionalarea => {
    return {
        type: FUNCTIONAL_AREA,
        Functionalarea
    };
};
export const jobPosted = status => {
    return {
        type: JOB_POSTED,
        status
    };
};


export const get_genders = () => {
    return dispatch => {
        axios.get(API_SERVER + 'gender/index')
            .then(resp => {
                dispatch(set_gender(resp.data))
            })
            .catch(error => {
                // console.log(error);
                dispatch(Logout());
            });
    };
};


export const getAllJobMelas = () => {
    return async dispatch => {
        const allMelas = axios.post(API_SERVER + 'job-melas/index').then(async resp => {
            return resp.data
        })
            .catch(error => {
                // console.log(error);
                dispatch(Logout());
            });
        const appliedMelas = axios.post(API_SERVER + 'mela-provider/view', qs.stringify({auth_key: localStorage.getItem('token')})).then(async resp => {
            return resp.data
        })
            .catch(error => {
                // console.log(error);
                dispatch(Logout());
            });
        Promise.all([allMelas, appliedMelas]).then(function (values) {
            const finalMelas = []
            values[0].forEach((item) => {
                let melas = values[1] && values[1].length ? values[1] : []
                let indexOfMela = melas.findIndex((applied) => applied.mela_id === item.id)
                melas = indexOfMela !== -1 ? values[1][indexOfMela] : ''
                finalMelas.push(Object.assign({}, item, melas))
            })
            dispatch(setJobMela(finalMelas));
        }).catch(error => {
            // console.log(error);
            dispatch(Logout());
        });
    };

};


export const registerForJobMela = (data) => {
    return dispatch => {
        data['auth_key'] = localStorage.getItem('token')
        dispatch(set_Loader());
        axios.post(API_SERVER + 'mela-provider/index', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '401') {
                    dispatch(setNotification({type: "error", message: resp.data.message}));
                } else {
                    dispatch(setNotification({type: "success", message: resp.data.message}));

                }
            })
            .catch(error => {
                console.log(error);
                // dispatch(Logout());
            });
    };
};

export const postJob = (data) => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'post-jobs/create', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '401') {
                    dispatch(setNotification({type: "error", message: resp.data.message}));
                } else {
                    dispatch(setNotification({type: "success", message: resp.data.message}));
                    dispatch(jobPosted(true));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const postSelectedCandidates = (data) => {
    // console.log('data', data)
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'accepted-record/index', qs.stringify(data))
            .then(resp => {
                // console.log(resp)

                dispatch(unset_Loader());
                if (resp.data.status === '401') {
                    dispatch(setNotification({type: "error", message: resp.data.message}));
                } else {
                    dispatch(setNotification({type: "success", message: resp.data.message}));
                    // dispatch(get_jobprovider_profile());
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};


export const get_job_shift = () => {
    return dispatch => {
        axios.get(API_SERVER + 'job-shift/view')
            .then(resp => {
                dispatch(set_job_shift(resp.data));
            })
            .catch(error => {
                // console.log(error);
                dispatch(Logout());
            });
    };
};

export const getCategory = () => {
    return dispatch => {
        axios.get(API_SERVER + 'ggr-user-reservation-category/view')
            .then(resp => {
                dispatch(setCategory(resp.data));
            })
            .catch(error => {
                // console.log(error);
                dispatch(Logout());
            });
    };
};

export const setCategory = category => {
    return {
        type: SET_CATEGORY,
        category
    };
};


export const get_User_Coursenature = () => {
    return dispatch => {
        axios.get(API_SERVER + 'ggr-course-nature/view')
            .then(resp => {

                dispatch(set_User_Coursenature(resp.data));
            })
            .catch(error => {
                // console.log(error);
                dispatch(Logout());
            });
    };
};
export const get_Coursenature = () => {
    return dispatch => {
        axios.get(API_SERVER + 'jobnatures/view')
            .then(resp => {
                dispatch(set_Coursenature(resp.data));
            })
            .catch(error => {
                // console.log(error);
                dispatch(Logout());
            });
    };
};


export const get_Salary_Type = () => {
    return dispatch => {
        axios.get(API_SERVER + 'salarytype/view')
            .then(resp => {
                dispatch(set_Salary_Type(resp.data));
            })
            .catch(error => {
                // console.log(error);
                dispatch(Logout());
            });
    };
};
export const get_jobprovider_profile = () => {
    return dispatch => {
        axios.post(API_SERVER + 'jobprovider-details/view', qs.stringify({auth_key: localStorage.getItem('token')}))
            .then(resp => {
                // debugger
                if (resp.data.status === '401') {
                    dispatch(setNotification({type: "error", message: resp.data.message}));
                } else {
                    dispatch(setJobProviderProfile(resp.data));
                }
            })
            .catch(error => {
                // console.log(error);
                dispatch(Logout());
            });
    };
};

export const get_update_profile = (data) => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'jobprovider-details/create', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '401') {
                    dispatch(setNotification({type: "error", message: resp.data.message}));
                } else {
                    dispatch(setNotification({type: "success", message: resp.data.message}));
                    dispatch(get_jobprovider_profile());
                }
            })
            .catch(error => {
                // console.log(error);
                dispatch(Logout());
            });
    };
};
export const get_functional_area = () => {
    return dispatch => {
        axios.get(API_SERVER + 'functional-area/view')
            .then(resp => {
                dispatch(set_functional_area(resp.data));
            })
            .catch(error => {
                // console.log(error);
                dispatch(Logout());
            });
    };
};
export const get_Sector = () => {
    return dispatch => {
        axios.get(API_SERVER + 'sector/view')
            .then(resp => {
                dispatch(set_Sector(resp.data));
            })
            .catch(error => {
                // console.log(error);
                dispatch(Logout());
            });
    };
};
export const get_User_Specilization = () => {
    return dispatch => {
        axios.get(API_SERVER + 'specialization/view')
            .then(resp => {
                dispatch(set_User_Specilization(resp.data));
            })
            .catch(error => {
                // console.log(error);
                dispatch(Logout());
            });
    };
};
export const get_User_Qualification = () => {
    return dispatch => {
        axios.get(API_SERVER + 'ggr-user-qualification/view')
            .then(resp => {
                dispatch(set_User_Qualification(resp.data));
            })
            .catch(error => {
                // console.log(error);
                dispatch(Logout());
            });
    };
};


export const get_User_Cities = (dist, type = 'currunt') => {
    return dispatch => {
        dispatch(set_Loader(''));
        axios.post(API_SERVER + 'ggr-city/view', qs.stringify({subdistrict_id: dist}))
            .then(resp => {
                dispatch(unset_Loader(''));
                if (type === 'currunt') {
                    dispatch(set_User_Cities(resp.data));
                } else {
                    dispatch(set_JUser_Cities(resp.data));
                }

            })
            .catch(error => {
                dispatch(unset_Loader(''));
                // console.log(error);
                dispatch(Logout());
            });
    };
};
export const get_User_Subistrict = (dist, type = 'currunt') => {
    return dispatch => {
        dispatch(set_Loader(''));
        axios.post(API_SERVER + 'ggr-user-subdistrict/view', qs.stringify({district_id: dist}))
            .then(resp => {
                dispatch(unset_Loader(''));
                if (type === 'currunt') {
                    dispatch(set_User_SubDistrict(resp.data));
                } else {
                    dispatch(set_JUser_SubDistrict(resp.data));
                }
            })
            .catch(error => {
                dispatch(unset_Loader(''));
                // console.log(error);
                dispatch(Logout());
            });
    };
};

export const get_User_District = () => {
    return dispatch => {
        axios.get(API_SERVER + 'state/index')
            .then(resp => {
                dispatch(set_User_District(resp.data))
            })
            .catch(error => {
                // console.log(error);
                dispatch(Logout());
            });
    };
};
export const get_org_type = () => {
    return dispatch => {
        axios.get(API_SERVER + 'ggr-organization-type/view')
            .then(resp => {
                dispatch(set_org_type(resp.data))
            })
            .catch(error => {
                // console.log(error);
                dispatch(Logout());
            });
    };
};

export const get_org_category = () => {
    return dispatch => {
        axios.get(API_SERVER + 'ggr-organization-category/view')
            .then(resp => {
                dispatch(set_org_category(resp.data))
            })
            .catch(error => {
                // console.log(error);
                dispatch(Logout());
            });
    };
};
export const get_org_state = () => {
    return dispatch => {
        axios.get(API_SERVER + 'state/index')
            .then(resp => {
                dispatch(set_org_state(resp.data))
            })
            .catch(error => {
                // console.log(error);
                dispatch(Logout());
            });
    };
};
export const get_User_Type = () => {
    return dispatch => {
        axios.get(API_SERVER + 'user_subtype/index')
            .then(resp => {
                dispatch(set_usersubtype(resp.data))

            })
            .catch(error => {
                // console.log(error);
                dispatch(Logout());
            });
    };
};

export const setNotification = Notification => {
    return {
        type: SET_NOTIFICATION,
        Notification
    };
}
export const Resendotp = (data) => {
    // console.log(data);
    return dispatch => {
        dispatch(set_Loader(''));
        axios.post(API_SERVER + 'site/resend-verification-email', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader(''));
                // console.log(resp);
                if (resp.data.status === '200') {
                    dispatch(set_OTP(''));
                    dispatch(setNotification({type: "success", message: resp.data.message}));
                } else {
                    dispatch(setNotification({type: "error", message: resp.data.message}));
                }
            })
            .catch(error => {
                dispatch(unset_Loader(''));
                if (error.response) {
                    dispatch(setNotification({type: "error", message: error.response.data}));

                } else if (error.request) {
                    // console.log(error.request);
                } else {
                    // console.log('Error', error.message);
                    dispatch(setNotification({type: "error", message: error.message}));
                }
            });
    };
}
export const user_forgetpass = (data) => {
    // console.log(data);
    return dispatch => {
        dispatch(set_Loader(''));
        axios.post(API_SERVER + 'site/request-password-reset', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader(''));
                // console.log(resp);
                if (resp.data.status === '200') {
                    dispatch(set_userreset(resp.data.message));
                    dispatch(setNotification({type: "success", message: resp.data.message}));
                } else {
                    dispatch(setNotification({type: "error", message: resp.data.message}));
                }
            })
            .catch(error => {
                dispatch(unset_Loader(''));
                if (error.response) {
                    dispatch(setNotification({type: "error", message: error.response.data}));

                } else if (error.request) {
                    // console.log(error.request);
                } else {
                    // console.log('Error', error.message);
                    dispatch(setNotification({type: "error", message: error.message}));
                }
            });
    };
}
export const user_verify = (data) => {
    // console.log(data);
    return dispatch => {
        dispatch(set_Loader(''));
        axios.post(API_SERVER + 'site/verify-email', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader(''));
                // console.log(resp);
                if (resp.data.status === '200') {
                    dispatch(set_userverified(resp.data.message));
                    dispatch(setNotification({type: "success", message: resp.data.message}));
                } else {
                    dispatch(setNotification({type: "error", message: resp.data.message}));
                }
            })
            .catch(error => {
                dispatch(unset_Loader(''));
                if (error.response) {
                    dispatch(setNotification({type: "error", message: error.response.data}));

                } else if (error.request) {
                    // console.log(error.request);
                } else {
                    // console.log('Error', error.message);
                    dispatch(setNotification({type: "error", message: error.message}));
                }
            });
    };
}
export const RegisterJobSeeker = (data) => {
    // console.log(data);
    return dispatch => {
        dispatch(set_Loader(''));
        axios.post(API_SERVER + 'site/signup', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader(''));
                if (resp.data.status === '401') {
                    dispatch(setNotification({type: "error", message: resp.data.message}));
                } else {
                    dispatch(set_RegisterUser(resp.data));
                }
            })
            .catch(error => {
                dispatch(unset_Loader(''));
                if (error.response) {
                    dispatch(setNotification({type: "error", message: error.response.data}));

                } else if (error.request) {
                    // console.log(error.request);
                    // dispatch(setNotification({type:"error",error.message}));
                } else {
                    // console.log('Error', error.message);
                    dispatch(setNotification({type: "error", message: error.message}));
                }
            });
    };
};

export const Islogin = () => {
    return dispatch => {
        axios.post(API_SERVER + 'site/index', qs.stringify({auth_key: localStorage.getItem('token')}))
            .then(resp => {
                // console.log(resp);
                if (resp.data.status === '401') {
                    dispatch(Logout());
                } else {
                    localStorage.setItem('token', resp.data.auth_key);
                    localStorage.setItem('login_type', resp.data.name);
                    localStorage.setItem('Islogin', true);
                    dispatch(setLoginuser(resp.data));
                }
            })
            .catch(error => {
                if (error.response) {
                    dispatch(setNotification({type: "error", message: error.response.data}));
                    dispatch(Logout());

                } else if (error.request) {
                } else {
                    // console.log('Error', error.message);
                    dispatch(setNotification({type: "error", message: error.message}));
                    dispatch(Logout());
                }
            });
    };
};
export const Logout = () => {
    return dispatch => {
        localStorage.setItem('token', 'null');
        localStorage.setItem('login_type', 'null');
        localStorage.setItem('Islogin', false);
        dispatch(setLoginuser([]));
        dispatch(setNotification({type: "success", message: "Logout Successfully.."}));
    };
};
export const Login = (data) => {
    return dispatch => {
        dispatch(set_Loader(''));
        axios.post(API_SERVER + 'site/login', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader(''));
                // console.log(resp);
                if (resp.data.status === '401' || resp.data.status === '204') {
                    if (resp.data.message === 'Account not verified') {
                        dispatch(set_RegisterUser(resp.data));
                        dispatch(setLoginuser('Account not verified'));
                    }
                    dispatch(setNotification({type: "error", message: resp.data.message}));
                } else {
                    localStorage.setItem('token', resp.data.auth_key);
                    localStorage.setItem('login_type', resp.data.name);
                    localStorage.setItem('Islogin', true);
                    dispatch(setLoginuser(resp.data));
                    dispatch(setNotification({type: "success", message: "Login Successfully.."}));
                }

            })
            .catch(error => {
                dispatch(unset_Loader(''));
                if (error.response) {
                    dispatch(setNotification({type: "error", message: error.response.data}));

                } else if (error.request) {
                } else {
                    // console.log('Error', error.message);
                    dispatch(setNotification({type: "error", message: error.message}));
                }
            });
    };
};
export const CreateUser = (newuser) => {
    return dispatch => {
        axios.post('/user/', newuser)
            .then(resp => {
                dispatch(setNotification({type: "success", message: "User created Successfully.."}));
            })
            .catch(error => {
                // console.log(error);
                // console.log(error.response.data.error);
                if (error.response.data.error === 11000) {
                    dispatch(setNotification({type: "error", message: "Email already exist..!"}));
                }
                if (error.response.data.error === 11001) {
                    dispatch(setNotification({type: "error", message: "Urlkey already exist..!"}));
                }
            });
    };
};
export const SignupUser = (newuser) => {
    return dispatch => {
        axios.post('/user/signup', newuser)
            .then(resp => {
                dispatch(setNotification({type: "success", message: "Login Successfully.."}));
                localStorage.setItem('token', resp.data.token);
                localStorage.setItem('Islogin', true);
                let user = resp.data.user;
                user.role = resp.data.role;
                user.business_id = resp.data.business_id;
                dispatch(setLoginuser(user));
            })
            .catch(error => {
                // console.log(error);
                if (error.response.data.error === 11000) {
                    dispatch(setNotification({type: "error", message: "Email already exist..!"}));
                }
                if (error.response.data.error === 11001) {
                    dispatch(setNotification({type: "error", message: "Urlkey already exist..!"}));
                }
            });
    };
};
export const UpdateUser = (newuser) => {
    return dispatch => {
        axios.post('/user/update', newuser)
            .then(resp => {
                // console.log(resp);
                if (resp.data.success === true || resp.data.success === 'true') {
                    dispatch(setNotification({type: "success", message: "User updated Successfully.."}));
                }
            })
            .catch(error => {
                // console.log(error);
                if (error.response.data.error === 11000) {
                    dispatch(setNotification({type: "error", message: "Email already exist..!"}));
                }
                if (error.response.data.error === 11001) {
                    dispatch(setNotification({type: "error", message: "Urlkey already exist..!"}));
                }
                dispatch(Logout());
            });
    };
};
export const UpdateProfile = (newuser) => {
    return dispatch => {
        axios.post('/user/profileupdate', newuser)
            .then(resp => {
                // console.log(resp);
                let user = resp.data.user;
                user.role = resp.data.role;
                user.business_id = resp.data.business_id;
                dispatch(setLoginuser(user));
            })
            .catch(error => {
                // console.log(error);
                if (error.response.data.error === 11000) {
                    dispatch(setNotification({type: "error", message: "Email already exist..!"}));
                }
                if (error.response.data.error === 11001) {
                    dispatch(setNotification({type: "error", message: "Urlkey already exist..!"}));
                }
                dispatch(Logout());
            });
    };
};

export const get_sector_self_employment = () => {
    return dispatch => {
        axios.get(API_SERVER + 'sector/index')
            .then(resp => {
                dispatch(set_Sector(resp.data));
            })
            .catch(error => {
                // console.log(error);
            });
    };
};

export const set_Sector = Sector => {
    return {
        type: SECTOR,
        Sector
    };
};

export const UpdateProfileImage = (newuser) => {
    return dispatch => {
        axios.post('/user/imageupdate', newuser)
            .then(resp => {
                // console.log(resp);
                let user = resp.data.user;
                user.role = resp.data.role;
                user.business_id = resp.data.business_id;
                dispatch(setLoginuser(user));
            })
            .catch(error => {
                // console.log(error);
                if (error.response.data.error === 11000) {
                    dispatch(setNotification({type: "error", message: "Email already exist..!"}));
                }
                if (error.response.data.error === 11001) {
                    dispatch(setNotification({type: "error", message: "Urlkey already exist..!"}));
                }
                dispatch(Logout());
            });
    };
};
export const UpdatePassword = (newuser) => {
    return dispatch => {
        axios.post('/user/profilepassword', newuser)
            .then(resp => {
                // console.log(resp);
                if (resp.data.success === true || resp.data.success === 'true') {
                    dispatch(setNotification({type: "success", message: "User updated Successfully.."}));
                }
            })
            .catch(error => {
                // console.log(error);
                dispatch(Logout());
            });
    };
};

// by kantsverma 
export const lspJobPost = (data) => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'service-provider/create', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '401') {
                    dispatch(setNotification({type: "error", message: resp.data.message}));
                } else {
                    dispatch(setNotification({type: "success", message: resp.data.message}));
                    dispatch(jobPosted(true));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};
  

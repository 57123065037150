import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { Link } from 'react-router'
import Validation from "../hoc/Validation";
import { sendFeedback, getVisitorCount } from '../../actions/accountAction'
import store from "../../store";
import preval from 'preval.macro'
import StarRatings from 'react-star-ratings'

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            basePath: window.location.protocol + '//' + window.location.host + '/',
            formData: [],
            error: ''
        }
        this.feedbackRef = React.createRef();
        this.disclaimer = React.createRef();
        store.dispatch(getVisitorCount())
    }

    submit = async (e) => {
        e.preventDefault()
        if (this.props.validator.allValid()) {
            await store.dispatch(sendFeedback(this.state.formData))
            await this.props.validator.hideMessages()
            this.setState({ formData: [] })
            this.feedbackRef.current.click()
        } else {
            this.props.validator.showMessages();
            this.forceUpdate();
        }
    }

    ratingChanged = (val) => {
        const formData = this.state.formData
        formData['rating'] = val
        this.setState({ formData })
    }

    change = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let formData = this.state.formData;

        formData[name] = value;
        this.setState({ formData: formData });
    }

    render() {
        const { validator } = this.props
        const { formData } = this.state

        return (
            <div className="clearfix">
                <div className="clearfix"></div>
                {this.props.location === '/' && <section className="client-logos text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 col-sm-4 col-lg-2 d-flex">
                                <img alt="" src="/images/banner1.png" className="img-fluid align-self-center mx-auto" />
                            </div>
                            <div className="col-md-2 col-sm-4 col-lg-2 d-flex">
                                <img alt="" src="/images/banner3.png" className="img-fluid align-self-center mx-auto" />
                            </div>
                            <div className="col-md-2 col-sm-4 col-lg-2 d-flex">
                                <img alt="" src="/images/banner4.png" className="img-fluid align-self-center mx-auto" />
                            </div>
                            <div className="col-md-2 col-sm-4 col-lg-2 d-flex">
                                <img alt="" src="/images/banner5.png" className="img-fluid align-self-center mx-auto" />
                            </div>
                            <div className="col-md-2 col-sm-4 col-lg-2 d-flex">
                                <img alt="" src="/images/banner6.jpg" className="img-fluid align-self-center mx-auto" />
                            </div>
                            <div className="col-md-2 col-sm-4 col-lg-2 d-flex">
                                <img alt="" src="/images/banner7.png" className="img-fluid align-self-center mx-auto" />
                            </div>
                        </div>
                    </div>
                </section>}

                <section className="upper-footer">
                    <div className="container">
                        <div className="row">
                            <ul className="mx-auto mb-0">
                                <li className="list-inline-item"><Link
                                >{this.props.t('footer.updatedOn', { param: 'react' })}
                                    {preval`module.exports = (new Date().getDate())+"/"+(new Date().getMonth()+1)+"/"+(new Date().getFullYear());`}</Link>
                                </li>
                                <li className="list-inline-item"><Link to="/copyright-policy"
                                >{this.props.t('footer.copyright', { param: 'react' })}</Link>
                                </li>
                                <li className="list-inline-item"><Link to="/privacy-policy"
                                > {this.props.t('footer.privacy_policy', { param: 'react' })}</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                {/* Feedback modal*/}
                <div className="modal" id="feedbackModal" ref={this.feedbackRef}>
                    <div className="modal-dialog">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h4 className="modal-title">{this.props.t('feedback.title', { param: 'react' })}</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>

                            <div className="modal-body">
                                <form onSubmit={this.submit}>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label>{this.props.t('forms.username', { param: 'react' })} : <span
                                                    className="ml-2"
                                                    style={{ "color": "red" }}>*</span></label>
                                                <input className="w-100 p-1"
                                                    onChange={this.change}
                                                    value={formData.username ? formData.username : ''}
                                                    placeholder={this.props.t('forms.enterUsername', { param: 'react' })}
                                                    style={{ "height": "200px !important" }}
                                                    name="username" />
                                                {validator.message('username', formData.username, 'required')}
                                            </div>


                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label>{this.props.t('profileDetails.mobile', { param: 'react' })} : <span
                                                    className="ml-2"
                                                    style={{ "color": "red" }}>*</span></label>

                                                <input className="w-100 p-1"
                                                    onChange={this.change}
                                                    value={formData.mobile ? formData.mobile : ''}
                                                    placeholder={this.props.t('forms.10mobile', { param: 'react' })}
                                                    style={{ "height": "200px !important" }}
                                                    name="mobile" />
                                                {validator.message('mobile', formData.mobile, 'required|phone')}
                                            </div>


                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <StarRatings
                                                    rating={formData.rating}
                                                    numberOfStars={5}
                                                    name='rating'
                                                    starDimension="80px"
                                                    starRatedColor="rgb(255, 215, 0)"
                                                    starHoverColor="rgb(255, 215, 0)"
                                                    changeRating={this.ratingChanged}

                                                />
                                                {validator.message('rating', formData.rating, 'required')}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label>{this.props.t('feedback.feedback', { param: 'react' })} : <span
                                                    className="ml-2"
                                                    style={{ "color": "red" }}>*</span></label>

                                                <textarea className="w-100 p-1"
                                                    onChange={this.change}
                                                    value={formData.feedback ? formData.feedback : ''}
                                                    placeholder={this.props.t('forms.enterFeedback', { param: 'react' })}
                                                    style={{ "height": "200px !important" }}
                                                    id="date" name="feedback" rows="4"
                                                    cols="8" />
                                                {validator.message('feedback', formData.feedback, 'required')}
                                            </div>


                                        </div>
                                    </div>
                                    <hr />
                                    <div className="form-group">
                                        <div className="row">


                                            <div className="col-sm-12">
                                                <button type="submit"
                                                    className="btn btn-primary btn-primary2 mx-auto d-block"
                                                    style={{ "marginRight": "10px" }}>{this.props.t('feedback.submit', { param: 'react' })}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="clearfix"></div>
                <section className="footer">
                    <div className="container">
                        <div className="row footer-border  ml-0">
                            <div className="col-md-12 col-sm-12 col-lg-12 first2">
                                <ul className="mx-auto mb-0">
                                    <Link style={{ "cursor": "pointer" }}>
                                        <li className="list-inline-item" data-toggle="modal" onClick={() => {
                                            this.setState({ formData: [] })
                                            this.props.validator.hideMessages()
                                        }} data-target="#feedbackModal"><span><img
                                            src={"/images/icon-feedback.png"}
                                            alt={""}
                                            style={{ "height": "25px" }} /></span> {this.props.t('footer.give_feedback', { param: 'react' })}
                                        </li>
                                    </Link>
                                    <li className="list-inline-item"><Link to="/contact-us"><span><img
                                        src={"/images/icon-contact.png"}
                                        alt={""}
                                        style={{ "height": "25px" }} /></span> {this.props.t('footer.contact_us', { param: 'react' })}
                                    </Link></li>
                                    <li className="list-inline-item"><Link to="/sitemap"
                                    >  {this.props.t('footer.Sitemap', { param: 'react' })}</Link>
                                    </li>
                                    <li className="list-inline-item"><Link to="/help"
                                    >  {this.props.t('footer.Help', { param: 'react' })}</Link>
                                    </li>
                                </ul>



                            </div>
                            <div className="col-md-12 col-sm-12 col-lg-12 first2">
                                <ul className="mx-auto mb-0">
                                    {/* <h6 className="text-center mb-0">{this.props.t('homeContent.disclaimer', {param: 'react'})}</h6> */}
                                    <li className="my-0"><marquee
                                        scrolldelay="150"
                                        ref={this.disclaimer}>
                                        <p onMouseLeave={(e) => this.disclaimer.current.start()}
                                            onMouseEnter={(e) => this.disclaimer.current.stop()}
                                            className="mb-1 "  >
                                            <b className="blinking" style={{ "fontSize": "14", "background": "none" }}>{this.props.t('homeContent.disclaimer', { param: 'react' })}:
                                                </b> {this.props.t('homeContent.disclaimerText', { param: 'react' })}</p></marquee></li>
                                </ul>
                            </div>
                            <div className="col-md-12 col-sm-12 col-lg-12 first2">
                                <span className="mx-auto mb-0" style={{"color": "#d7d1d1"}}>
                                    Portal is best viewed in IE 10 and above, supports Chrome, Firefox Browsers with 1024 x 768 resolution and above.
                                </span>    
                            </div>
                        </div>
                        <div className="row">
                            <div className=" col-lg-4  col-sm-4 pt-2 education-copyright text-left ">
                                <p>© {new Date().getFullYear()}, {this.props.t('footer.all_right_reserved', { param: 'react' })}{" "}
                                    <a target="_blank" href="/" style={{ "fontWeight": "700" }}>{this.props.t('footer.ggr', { param: 'react' })} </a></p>
                            </div>
                            <div className=" col-lg-4  col-sm-4 pt-2 education-copyright"> {this.props.t('footer.visitCount', { param: 'react' })}:
                           <span style={{ "fontWeight": "700" }}>{this.props.actionReducer.visitorsCount && this.props.actionReducer.visitorsCount}</span>
                            </div>
                            <div className=" col-lg-4  col-sm-4 pt-2 education-copyright">
                                <p className="pull-right"><span>{this.props.t('footer.created_by', { param: 'react' })} <a
                                    href="https://www.drishinfo.com/"
                                    rel="noopener noreferrer"
                                    target="_blank">{this.props.t('footer.drish', { param: 'react' })}.</a></span></p>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="clearfix"></div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(Validation(Footer)));

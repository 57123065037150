import {
    SET_ORGANIZATION_TYPE, SET_SEARCH_RESULTS, SET_CAPACITY, USER_JOBSEEKER_EDUCATIONS, SET_UPCOMING_JOBS,
    SET_JOBSEEKER_ADDRESSES, SET_DISABILITY, SET_JOBSEEKER_PROFILE, SET_EYESIGHT, SET_BLOOD_G, SET_JOB_INVITES,
    SET_SKILL_SEARCH, SET_COUNSELLING_RESULTS, SET_JOB_MELA_RESULTS, SET_JOIN_BY_DAYS, SET_SCHEDULED_JOBS,
    SET_MARGIN_MONEY, SET_SUBSIDY, SET_AMOUNT, SET_COUNSELLING_TYPE, SET_SELF_EMPLOYMENT_SEARCH_RESULTS,
    SET_MARITAL, SET_RELIGION, SET_CATEGORY, SET_RELATION, SET_JOBSEEKER_PHYSICAL, SET_SCHEDULED_SKILLS,
    SET_UPCOMING_SKILLS, SET_RECOMMENDED_JOBS, SET_RECOMMENDED_SKILLS, SET_RECOMMENDED_COUNSELLING,
    SET_RECOMMENDED_MELAS, SET_APPLIED_PAGES, SET_CAREER_COUNSELLING, SET_SCHEDULED_JOB_MELA, SET_SEEKER_EDUCATION,
    SET_EXPERIENCE_RESULTS, USER_JOBSEEKER_EXPERIENCE, SET_JOB_MELA, SET_JOB_MELA_ATTECH, SET_ADDITIONAL_SKILLS,
    SET_SEEKER_PERCENTAGE, SET_QUALIFICATION, SET_MESSAGE, SET_STATUS, SET_HALL_TICKET,
    HALL_TICKET_PRINT, APPLY_FOR_COUNSELLING, SET_SKILL_APPLIED, APPLY_FOR_JOB, EXP_UPDATED, SET_JOBS_APPLIED,
    SET_SELF_EMPLOYMENT_BY_ID, SET_COUNSELLING_BY_ID, SET_CAREER, SET_SCHEDULED_STUDY, SET_SCHEDULED_FOREIGN_JOBS,
    SET_SCHEDULED_SELF_EMPLOYMENT,SET_FOREIGN_JOBS_INTERVIEW, SET_UPCOMING_SELF_EMPLOYMENT, SET_APPLIED_LOCAL_SERVICES,
    SET_ALL_JOB_BY_EDU, TAB_SUBMITTED, SET_PROGRESS, SET_USER_PROFILE_INTEREST, SET_COUNSELLOR_SEARCH_RESULT, SET_ISSUE_TYPES,
    SET_TICKETS, SET_TRAINING_INTEREST, SET_SELF_EMP_INTEREST, SET_SELF_EMP_CAREERS, SET_RELEVANT_SELF_EMPLOYMENT,
    SET_SCHEDULED_SERVICE, SET_FOREIGN_COUNSELLING
}
    from '../actions/actionTypes';

const initialState = {
    Profile: [],
    Physical: [],
    BloodGroups: [],
    Educations: [],
    Addresses: {current: [], permanent: []},
    EyeSight: [],
    Marital: [],
    Disability: [],
    Religion: [],
    Capacity: [],
    Category: [],
    Relation: [],
    schduledServices:[],
    organisations: [],
    searchResults: [],
    money: [],
    subsidy: [],
    amount: [],
    counsellingType: [],
    selfEmployment: [],
    skillResults: [],
    counsellingResults: [],
    jobMelaResults: [],
    joinByDays: [],
    scheduledJobs: [],
    jobInvites: [],
    upComingJobs: [],
    upComingSkills: [],
    scheduledSkills: [],
    recommendedJobs: [],
    recommendedSkills: [],
    recommendedCounselling: [],
    recommendedMelas: [],
    appliedPages: [],
    scheduledCounselling: [],
    scheduledJobMela: [],
    experience: [],
    Experience: [],
    jobMelaAttech: [],
    jobMela: [],
    additionalSkills: [],
    educationForJobMela: [],
    percentage: [],
    qualification: [],
    msg: '',
    status: false,
    hallTicket:[],
    hallTicketForPrint:[],
    hallTicketData:[],
    appliedForCounselling: false,
    skillApplied:false,
    jobApplied:false,
    experienceUpdated:false,
    appliedJobs:[],
    selfEmploymentData:[],
    counsellingById: [],
    careerResults:[],
    scheduledStudy:[],
    scheduledForeignJobs:[],
    scheduledSelfEmployment:[],
    scheduledForeignJobsInterview:[],
    upComingSelfEmployment:[],
    appliedServices:[],
    jobsByEdu:[],
    profileTabSubmitted:'',
    progress:20,
    profile_interest:[],
    counsellorResults:[],
    issueTypes:[],
    tickets:[],
    training_interest:0,
    interest:0,
    careerOpt:[],
    relevantSelfEmp:[],
    foreignCounselling:[]
};
const setRelevantSelfEmployment = (state, action) => {
    return {...state, relevantSelfEmp: action.data};
};

const setMatchingCareers = (state, action) => {
    return {...state, careerOpt: action.data};
};

const setTickets = (state, action) => {
    return {...state, tickets: action.data};
};

const setIssueTypes = (state, action) => {
    return {...state, issueTypes: action.data};
};

const setCounsellingSearchByArea = (state, action) => {
    return {...state, counsellorResults: action.data};
};

const setUserProfileInterest = (state, action) => {
    return {...state, profile_interest: action.data};
};

const setProfile = (state, action) => {
    return {...state, Profile: action.Profile};
};
const setProgress = (state, action) => {
    // // console.log(action);
    return {...state, progress: action.data};
};

const setAddress = (state, action) => {
    let Addresses = {current: [], permanent: []};
    Addresses.current = action.Address[0];
    if (action.Address.hasOwnProperty(1)) {
        Addresses.permanent = action.Address[1];
    }
    return {...state, Addresses: Addresses};
};
const setPhysical = (state, action) => {
    return {...state, Physical: action.Physical};
};
const setMarital = (state, action) => {
    return {...state, Marital: action.Maritalstatues};
};
const setBloodGroups = (state, action) => {
    return {...state, BloodGroups: action.BloodGroups};
};
const setEyeSight = (state, action) => {
    return {...state, EyeSight: action.EyeSight};
};
const setDisability = (state, action) => {
    return {...state, Disability: action.Disability};
};
const setReligion = (state, action) => {
    
    return {...state, Religion: action.Religionstatues};
};
const setCapacity = (state, action) => {
    
    return {...state, Capacity: action.Capacity};
};
const setCategory = (state, action) => {
    
    return {...state, Category: action.Categorystatues};
};
const setRelation = (state, action) => {
    
    return {...state, Relation: action.Relationstatues};
};
const setEducations = (state, action) => {
    
    return {...state, Educations: action.Educations};
};

const setOrganizationType = (state, action) => {
    
    return {...state, organisations: action.organisation};
}

const setSchduledServices = (state, action) => {
    return {...state, schduledServices: action.data};
};

const setSearchResults = (state, action) => {
    
    return {...state, searchResults: action.results};
}

const setMarginMoney = (state, action) => {
    
    return {...state, money: action.money};
}

const setSubsidy = (state, action) => {
    
    return {...state, subsidy: action.subsidy};
}

const setAmount = (state, action) => {
    
    return {...state, amount: action.amount};
}

const setCounsellingType = (state, action) => {
    
    return {...state, counsellingType: action.counsellingType};
}
const setSelfEmploymentResults = (state, action) => {
    
    return {...state, selfEmployment: action.results};
}

const setSelfEmployment = (state, action) => {
    
    return {...state, selfEmploymentData: action.data};
}


const setSkillResults = (state, action) => {
    
    return {...state, skillResults: action.results};
}
const setCounsellingResults = (state, action) => {
    
    return {...state, counsellingResults: action.results};
}
const setJobMelaResults = (state, action) => {
    
    return {...state, jobMelaResults: action.results};
}
const setJobMela = (state, action) => {
    
    return {...state, jobMela: action.results};
}
const setJoinByDays = (state, action) => {
    
    return {...state, joinByDays: action.days};
}
const setScheduledJobs = (state, action) => {
    
    return {...state, scheduledJobs: action.jobs};
}
const setJobInvites = (state, action) => {
    
    return {...state, jobInvites: action.invites};
}
const setUpcomingJobs = (state, action) => {
    
    return {...state, upComingJobs: action.jobs};
}
const setUpcomingSkills = (state, action) => {
    
    return {...state, upComingSkills: action.skills};
}
const setScheduledSkills = (state, action) => {
    
    return {...state, scheduledSkills: action.skills};
}
const setRecommendedJobs = (state, action) => {
    
    return {...state, recommendedJobs: action.jobs};
}
const setRecommendedSkills = (state, action) => {
    
    return {...state, recommendedSkills: action.skills};
}
const setRecommendedCounselling = (state, action) => {
    
    return {...state, recommendedCounselling: action.counselling};
}
const setRecommendedMelas = (state, action) => {
    
    return {...state, recommendedMelas: action.melas};
}
const setAppliedPages = (state, action) => {
    
    return {...state, appliedPages: action.pages};
}
const setScheduledCareerCounselling = (state, action) => {
    
    return {...state, scheduledCounselling: action.counselling};
}
const setScheduledJobMela = (state, action) => {
    
    return {...state, scheduledJobMela: action.jobMela};
}
const setExperience = (state, action) => {
    
    return {...state, experience: action.results};
}
const setSeekerExperience = (state, action) => {
    
    return {...state, Experience: action.Experience};
};
const setAdditionalSkills = (state, action) => {
    
    return {...state, additionalSkills: action.skills};
};
const setSeekerEducation = (state, action) => {
    
    return {...state, educationForJobMela: action.education};
};
const setSeekerPercentage = (state, action) => {
    
    return {...state, percentage: action.percentage};
};
const setQualification = (state, action) => {
    
    return {...state, qualification: action.qualification};
};
const setMessage = (state, action) => {
    
    return {...state, msg: action.msg};
};
const setStatus = (state, action) => {
    
    return {...state, status: action.status};
};
const setHallTicket = (state, action) => {
    return {...state, hallTicket: action.results};
};

const setAppliedJobs = (state, action) => {
    
    return {...state, appliedJobs: action.data};
};
const setHallTicketForPrint = (state, action) => {
    return {...state, hallTicketForPrint: action.results};
};
const setApplyForCounselling = (state, action) => {
    return {...state, appliedForCounselling: action.status};
};
const setSkillApplied = (state, action) => {
    return {...state, skillApplied: action.status};
};
const jobApplied = (state, action) => {
    return {...state, jobApplied: action.status};
};
const expUpdated = (state, action) => {
    return {...state, experienceUpdated: action.status};
};
const setCounsellingById = (state, action) => {
    return {...state, counsellingById: action.data};
};
const setCareer = (state, action) => {
    return {...state, careerResults: action.data};
};
const setScheduledForeignJobs = (state, action) => {
    return {...state, scheduledForeignJobs: action.data};
};

const setTrainingInterest = (state, action) => {
    return {...state, training_interest: action.status};
};
const setScheduledStudy = (state, action) => {
    return {...state, scheduledStudy: action.data};
};

const setScheduledSelfEmployment = (state, action) => {
    return {...state, scheduledSelfEmployment: action.data};
};
const setForeignJobsInterview = (state, action) => {
    return {...state, scheduledForeignJobsInterview: action.data};
};

const setUpcomingSelfEmployment = (state, action) => {
    return {...state, upComingSelfEmployment: action.data};
};

const setAppliedServices = (state, action) => {
    return {...state, appliedServices: action.data};
};

const setJobsByEdu = (state, action) => {
    return {...state, jobsByEdu: action.jobs};
};

const setProfileTabSubmitted = (state, action) => {
    // // console.log(action);
    return {...state, profileTabSubmitted: action.status};
};

const setUserSelfEmpInterest = (state, action) => {
    return {...state, interest: action.status};
};

const setForeignCounselling = (state, action) => {
    return {...state, foreignCounselling: action.data};
};





const setJobMelaAttech = (state, action) => {
    
    let jobMelaAttech = state.jobMelaAttech;
    jobMelaAttech[action.e_type] = action.message;
    return {...state, jobMelaAttech: jobMelaAttech};
};
const JobseekerReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_JOBSEEKER_PROFILE:
            return setProfile(state, action);
        case SET_JOBSEEKER_PHYSICAL:
            return setPhysical(state, action);
        case SET_JOBSEEKER_ADDRESSES:
            return setAddress(state, action);
        case SET_MARITAL:
            return setMarital(state, action);
        case SET_RELIGION:
            return setReligion(state, action);
        case SET_DISABILITY:
            return setDisability(state, action);
        case SET_BLOOD_G:
            return setBloodGroups(state, action);
        case USER_JOBSEEKER_EDUCATIONS:
            return setEducations(state, action);
        case SET_EYESIGHT:
            return setEyeSight(state, action);
        case SET_CAPACITY:
            return setCapacity(state, action);
        case SET_CATEGORY:
            return setCategory(state, action);
        case SET_RELATION:
            return setRelation(state, action);
        case SET_ORGANIZATION_TYPE:
            return setOrganizationType(state, action);
        case SET_JOB_MELA_ATTECH:
            return setJobMelaAttech(state, action);
        case SET_SEARCH_RESULTS:
            return setSearchResults(state, action);
        case SET_MARGIN_MONEY:
            return setMarginMoney(state, action);
        case SET_SUBSIDY:
            return setSubsidy(state, action);
        case SET_AMOUNT:
            return setAmount(state, action);
        case SET_COUNSELLING_TYPE:
            return setCounsellingType(state, action);
        case SET_SELF_EMPLOYMENT_SEARCH_RESULTS:
            return setSelfEmploymentResults(state, action);
        case SET_SKILL_SEARCH:
            return setSkillResults(state, action);
        case SET_COUNSELLING_RESULTS:
            return setCounsellingResults(state, action);
        case SET_JOB_MELA_RESULTS:
            return setJobMelaResults(state, action);
        case SET_JOB_MELA:
            return setJobMela(state, action);
        case SET_JOIN_BY_DAYS:
            return setJoinByDays(state, action);
        case SET_SCHEDULED_JOBS:
            return setScheduledJobs(state, action);
        case SET_JOB_INVITES:
            return setJobInvites(state, action);
        case SET_UPCOMING_JOBS:
            return setUpcomingJobs(state, action);
        case SET_SCHEDULED_SKILLS:
            return setScheduledSkills(state, action);
        case SET_UPCOMING_SKILLS:
            return setUpcomingSkills(state, action);
        case SET_RECOMMENDED_JOBS:
            return setRecommendedJobs(state, action);
        case SET_RECOMMENDED_SKILLS:
            return setRecommendedSkills(state, action);
        case SET_RECOMMENDED_COUNSELLING:
            return setRecommendedCounselling(state, action);
        case SET_RECOMMENDED_MELAS:
            return setRecommendedMelas(state, action);
        case SET_APPLIED_PAGES:
            return setAppliedPages(state, action);
        case SET_CAREER_COUNSELLING:
            return setScheduledCareerCounselling(state, action);
        case SET_SCHEDULED_JOB_MELA:
            return setScheduledJobMela(state, action);
        case SET_EXPERIENCE_RESULTS:
            return setExperience(state, action);
        case USER_JOBSEEKER_EXPERIENCE:
            return setSeekerExperience(state, action);
        case SET_ADDITIONAL_SKILLS:
            return setAdditionalSkills(state, action);
        case SET_SEEKER_EDUCATION:
            return setSeekerEducation(state, action);
        case SET_SEEKER_PERCENTAGE:
            return setSeekerPercentage(state, action);
        case SET_QUALIFICATION:
            return setQualification(state, action);
        case SET_MESSAGE:
            return setMessage(state, action);
        case SET_STATUS:
            return setStatus(state, action);
        case SET_HALL_TICKET:
            return setHallTicket(state, action);
        
        case HALL_TICKET_PRINT:
            return setHallTicketForPrint(state, action);
        case APPLY_FOR_COUNSELLING:
            return setApplyForCounselling(state, action);
        case SET_SKILL_APPLIED:
            return setSkillApplied(state, action);
        case APPLY_FOR_JOB:
            return jobApplied(state, action);
        case EXP_UPDATED:
            return expUpdated(state, action);
        case SET_JOBS_APPLIED: return setAppliedJobs(state, action);
        case SET_SELF_EMPLOYMENT_BY_ID: return setSelfEmployment(state, action);
        case SET_COUNSELLING_BY_ID: return setCounsellingById(state, action);
        case SET_CAREER: return setCareer(state, action);
        case SET_SCHEDULED_STUDY: return setScheduledStudy(state, action);
        case SET_SCHEDULED_FOREIGN_JOBS: return setScheduledForeignJobs(state, action);
        
        case SET_SCHEDULED_SELF_EMPLOYMENT: return setScheduledSelfEmployment(state, action);
        case SET_FOREIGN_JOBS_INTERVIEW: return setForeignJobsInterview(state, action);
        case SET_UPCOMING_SELF_EMPLOYMENT: return setUpcomingSelfEmployment(state, action);
        case SET_APPLIED_LOCAL_SERVICES: return setAppliedServices(state, action);
        case SET_ALL_JOB_BY_EDU: return setJobsByEdu(state, action);
        case TAB_SUBMITTED: return setProfileTabSubmitted(state, action)
        case SET_PROGRESS: return setProgress(state, action)
        case SET_USER_PROFILE_INTEREST: return setUserProfileInterest(state, action)
        case SET_COUNSELLOR_SEARCH_RESULT: return setCounsellingSearchByArea(state, action)
        case SET_TICKETS: return setTickets(state, action)
        case SET_ISSUE_TYPES: return setIssueTypes(state, action)
        case SET_TRAINING_INTEREST: return setTrainingInterest(state, action)
        case SET_SELF_EMP_INTEREST: return setUserSelfEmpInterest(state, action)
        case SET_SELF_EMP_CAREERS: return setMatchingCareers(state, action)
        case SET_RELEVANT_SELF_EMPLOYMENT: return setRelevantSelfEmployment(state, action);
        case SET_SCHEDULED_SERVICE: return setSchduledServices(state, action)
        case SET_FOREIGN_COUNSELLING: return setForeignCounselling(state, action); 
        
        default:
            return state;
    }
};
export default JobseekerReducer;
